import Cookie from "js-cookie";
import { API_ROOT } from "src/model/http.typer";
import { Method } from "src/model/http.typer";

import { VehicleExcelExportRequest } from "@model/gen";

export async function handleVehicleExcelExport(requestBody: Array<VehicleExcelExportRequest>) {
    const filenameResolver = (res: Response) =>
        res.headers.get("content-disposition")!.split(";")[1].split("filename=")[1];

    const xsrfToken = Cookie.get("XSRF-TOKEN");
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": String(xsrfToken)
    };

    return fetch(`${API_ROOT}exceluttrekk/kjoretoy`, {
        body: JSON.stringify(requestBody),
        method: Method.POST,
        headers
    }).then((response) =>
        response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = filenameResolver(response);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
    );
}
