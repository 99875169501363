import { useEffect } from "react";

import { Await } from "react-router-dom";
import { Link } from "react-router-dom";
import { LenkeId, useExternalLinks } from "src/common/lenker";
import { Side, SideInnhold } from "src/components/Side";
import { Trackingkey, track } from "src/tracking";

import { Typography } from "@components/Typography";
import { useBruker } from "@features/bruker/queries";
import { useFetchDokumentListe } from "@features/dokumenter/queries";
import { TaskList } from "@features/tasks/components/TaskList/TaskList";
import { useTasks } from "@features/tasks/tasks.queries";
import { NavCard } from "@fremtind/jkl-card-react";

import "./Hjem.scss";
import { AlertSection } from "./components/AlertSection";
import { HomeHeader } from "./components/header/HomeHeader";

export function Home() {
    const { bruker, valgtOrganisasjon } = useBruker();
    const taskList = useTasks();
    const privatForsikringLink = useExternalLinks().getLinkByLinkId(LenkeId.privatforsikring)?.href;
    const pensjonLink = useExternalLinks().getLinkByLinkId(LenkeId.pensjon)?.href;

    const { setShouldRunQuery } = useFetchDokumentListe();

    useEffect(() => {
        setTimeout(() => {
            setShouldRunQuery(true);
        }, 3000);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Side>
            <HomeHeader
                organizationName={valgtOrganisasjon?.navn ?? ""}
                organizationNumber={valgtOrganisasjon?.organisasjonsnummer ?? ""}
            />

            <SideInnhold className="bm-home">
                <Await resolve={taskList}>
                    {(tasks) => (
                        <TaskList
                            heading={<Typography className="jkl-spacing-l--bottom" variant="heading-2">Dine oppgaver</Typography>}
                            className="tasks"
                            category="Oppgave"
                            status={["sendt", "lest"]}
                            tasks={tasks.data}
                            sort={{ sortBy: "createdDate", order: "desc" }}
                        />
                    )}
                </Await>
                {bruker && <AlertSection bruker={bruker} />}
                <section className="shortcuts">
                    <Typography variant="heading-2" className="jkl-spacing-l--bottom">Snarveier</Typography>
                    <ul>
                        <li>
                            <NavCard
                                title="Dine forsikringer"
                                description="Avtalene du har hos oss i dag"
                                component={Link}
                                to="/forsikringer"
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "forsikringer",
                                        type: "forside"
                                    });
                                }}
                            />
                        </li>
                        <li>
                            <NavCard
                                title="Faktura"
                                description="Se alle fakturaer for bedriften din"
                                component={Link}
                                to="/faktura"
                            />
                        </li>
                        <li>
                            <NavCard
                                title="Dokumenter"
                                description="Informasjon vi har sendt bedriften din"
                                component={Link}
                                to="/dokumenter"
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "dokumenter",
                                        type: "forside"
                                    });
                                }}
                            />
                        </li>
                    </ul>
                </section>
                <Await resolve={taskList}>
                    {(tasks) => (
                        <TaskList
                            className={"aktuelt two-col-grid"}
                            heading={<Typography variant="heading-2">Aktuelt</Typography>}
                            category="Aktuelt"
                            status={["sendt", "lest"]}
                            tasks={tasks.data}
                            sort={{ sortBy: "createdDate", order: "desc" }}
                            showImages={true}
                            showOffers={true}
                        ></TaskList>
                    )}
                </Await>
                <section className="other">
                    <Typography variant="heading-2">Ser du etter disse?</Typography>
                    {pensjonLink && (
                        <NavCard
                            title="Pensjon"
                            description="Oversikt over pensjonssparingen din"
                            href={pensjonLink}
                            target="_blank"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: "pensjon",
                                    type: "forside"
                                });
                            }}
                        />
                    )}
                    {privatForsikringLink && (
                        <NavCard
                            title="Dine privatforsikringer"
                            description="Forsikringer registrert på deg som person"
                            href={privatForsikringLink}
                            target="_blank"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: "privatforsikring",
                                    type: "forside"
                                });
                            }}
                        />
                    )}
                </section>
            </SideInnhold>
        </Side>
    );
}
