import { type FC } from "react";

import { Typography } from "@components/Typography";
import { Card } from "@fremtind/jkl-card-react";
import { formatTelefonnummer } from "@fremtind/jkl-formatters-util";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import type { GrunndataContactInfoDto } from "@model/gen";

import "./GrunndataGoldenRecords.scss";

interface Props {
    goldenRecords: GrunndataContactInfoDto["goldenRecords"];
    error?: GrunndataContactInfoDto["klientFeilmeldinger"];
}

export const GrunndataGoldenRecordsList: FC<Props> = ({ goldenRecords, error }) => {
    if (error && error?.length > 0) return <ErrorMessageBox>Klarte ikke å hente kontaktpunkter</ErrorMessageBox>;

    if (!goldenRecords || goldenRecords?.length === 0)
        return <Typography data-testid="empty-state">Ingen registrerte kontaktpunkter.</Typography>;

    return (
        <ul className="golden-records__list">
            {goldenRecords?.map((record, index) => (
                <li key={index}>
                    <Card className="golden-records__list__item">
                        {record.organizationName && (
                            <Typography data-testid="golden-records-name" bold>
                                {record.organizationName}
                            </Typography>
                        )}
                        {record.phoneNumbers && (
                            <ul data-testid="golden-records-phone">
                                {record.phoneNumbers.map((number, index) => (
                                    <li key={index}>{`${number.countryCode} ${formatTelefonnummer(number.number)}`}</li>
                                ))}
                            </ul>
                        )}
                        {record.email && <Typography data-testid="golden-records-mail">{record.email}</Typography>}
                        {record.postalAddress && (
                            <Typography data-testid="golden-records-address">{record.postalAddress}</Typography>
                        )}
                    </Card>
                </li>
            ))}
        </ul>
    );
};
