import { SkeletonElement } from "@fremtind/jkl-loader-react";

import "./Skeleton.scss";

interface SkeletonTableProps {
    columns: number;
    rows: number;
    contentHeight?: number;
    contentWidths?: number[];
    headingWidths?: number[];
    colsHalfWidth?: boolean;
}

export const BmSkeletonTable = (props: SkeletonTableProps) => {
    //default to 100 for heading and 200 for content and if only one width is provided: default to that
    const headingWidth = (index: number) => {
        if (!props.headingWidths) {
            return 100;
        }
        return props.headingWidths[index] ? props.headingWidths[index] : props.headingWidths[0];
    };

    const contentWidth = (index: number) => {
        if (props.colsHalfWidth) {
            return "100%";
        }
        if (!props.contentWidths) {
            return 200;
        }
        return props.contentWidths[index] ? props.contentWidths[index] : props.contentWidths[0];
    };

    return (
        <div className="skeleton-table">
            <div className="skeleton-table__header">
                <div className="skeleton-table__row">
                    {Array.from(Array(props.columns)).map((_val, index) => (
                        <div key={index} style={{ width: props.colsHalfWidth ? "50%" : contentWidth(index) }}>
                            <SkeletonElement width={headingWidth(index)} height={16} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="skeleton-table__body">
                {Array.from(Array(props.rows)).map((_val, rowIndex) => (
                    <div key={rowIndex} className="skeleton-table__row">
                        {Array.from(Array(props.columns)).map((_val, columnIndex) => (
                            <div key={columnIndex} className={props.colsHalfWidth ? "half-width" : ""}>
                                <SkeletonElement
                                    height={props.contentHeight ? props.contentHeight : 40}
                                    width={contentWidth(columnIndex)}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
