import ".././IkkeFunnetFeil/IkkeFunnetFeil.scss";

export function FilVisningsFeil() {
    return (
        <div className="not-found">
            <div className="not-found__informasjon">
                <h2 className="jkl-title">Oi, dette gikk ikke.</h2>
                <p className="jkl-body not-found__informasjon--hjelpe-tekst">
                    <span>Vi klarer ikke hente dokumentet ditt. Lukk denne fanen og prøv igjen.</span>
                </p>
            </div>
        </div>
    );
}
