import type { ReactNode } from "react";

interface TypographyProps {
    variant?: "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | string;
    children: ReactNode;
}

export const ScreenReaderText = ({ variant = "p", children }: TypographyProps) => {
    const Tag = variant as keyof JSX.IntrinsicElements;
    return <Tag className="jkl-sr-only">{children}</Tag>;
};
