import { useEffect } from "react";

import { useFormContext } from "react-hook-form";

import { SecondaryLinkButton } from "@components/Button";
import { FlowFooter } from "@components/SkjemaFlyt";
import { Typography } from "@components/Typography";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { FieldValues, type StepProps } from "./EndreAntall";
import { EndreAntallRad } from "./EndreAntallRad";
import { onlyEdited } from "./utils";

type Props = StepProps;

export function Kvittering({ onNext, endringerState }: Props) {
    const form = useFormContext<FieldValues>();

    const allChangeStates = Object.values(endringerState).flatMap((e) => Object.values(e));
    const showSuccess = allChangeStates.some((e) => e === "RESOLVED");
    const showError = allChangeStates.some((e) => e === "REJECTED");

    useEffect(() => {
        onNext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const avtalerToShow = form.getValues().avtaler.reduce(onlyEdited, []);

    return (
        <>
            <Typography className="-mt-32">
                {
                    // Ingenting gikk bra
                    showError && !showSuccess
                        ? "Kunne ikke oppdatere antall"
                        : // Noe gikk bra
                          showError && showSuccess
                          ? "Du har gjort følgende endringer:"
                          : // Alt gikk bra
                            "Flott! Du har gjort følgende endringer:"
                }
            </Typography>
            <div className="kvittering">
                {avtalerToShow.map((avtale, index) => (
                    <EndreAntallRad
                        avtale={avtale}
                        avtaleIndex={index}
                        key={avtale.id}
                        endringerStatus={endringerState[avtale.id]}
                        showStatus
                    />
                ))}
            </div>

            {showError && (
                <ErrorMessageBox title="Noe gikk galt" fullWidth={true} className="jkl-spacing-xl--top">
                    Dessverre ble ikke alle endringene oppdatert. Se i listen over for hvilke objekt det gjelder. Prøv
                    en gang til eller kom tilbake senere.
                </ErrorMessageBox>
            )}

            <FlowFooter>
                <SecondaryLinkButton to="/ansatte-antall">Ferdig</SecondaryLinkButton>
            </FlowFooter>
        </>
    );
}
