import { Featureflag, Flag, useFeatureflag } from "src/common/featureflag";

import { agreementStatusIsRenewal } from "@features/agreements/avtale.utils";
import { InfoMessageBox, WarningMessageBox } from "@fremtind/jkl-message-box-react";
import { AgreementOverview } from "@model/gen";

interface Props {
    agreements: Array<AgreementOverview>;
}

export const InfoMessages = ({ agreements }: Props) => {
    const hasRenewalAgreements = agreements.some(agreementStatusIsRenewal);
    const hasMaintenance = useFeatureflag(Flag.FEILSIDE_OPPGRADERING_TILGANGSBASEN);

    const infoMsgList = [hasRenewalAgreements, hasMaintenance];
    const infoMsgListToDisplay = infoMsgList.some((item) => item === true);

    if (!infoMsgListToDisplay) {
        return false;
    }

    return (
        <div className="infomessages">
            <Featureflag flag={Flag.FEILSIDE_OPPGRADERING_TILGANGSBASEN}>
                <div className={"max-w-3xl"}>
                    <WarningMessageBox className="agreement-overview__info-message" fullWidth>
                        På grunn av teknisk vedlikehold vil skadeforsikringer være utilgjengelige den 27. oktober fra
                        06:00 til 12:00.
                    </WarningMessageBox>
                </div>
            </Featureflag>
            {hasRenewalAgreements && (
                <div className={"max-w-3xl"}>
                    <InfoMessageBox
                        className="agreement-overview__info-message"
                        fullWidth
                        title="Du har forsikringer til fornyelse"
                    >
                        Forsikringsavtalene gjelder for ett år av gangen og vil fornyes automatisk. Dette kan føre til
                        ny pris og endringer av vilkår. Klikk deg inn på avtalen for å gjøre endringer.
                    </InfoMessageBox>
                </div>
            )}
        </div>
    );
};
