import { useFormContext } from "react-hook-form";
import { asDate } from "src/common/formatting";

import { TextInput } from "@components/TextInput";
import { formatDate } from "@fremtind/jkl-formatters-util";

import { MileagePerYearInputs } from "../../MileagePerYear";

export const MileageInput: React.FC<{
    className?: string;
    lastChecked: string | undefined;
}> = ({ className, lastChecked }) => {
    const lastCheckedDate = asDate(lastChecked || "");
    const lastCheckedText = lastChecked ? `Sist oppdatert ${formatDate(lastCheckedDate)}` : "";

    const { register, formState } = useFormContext<MileagePerYearInputs>();

    return (
        <TextInput
            className={className}
            labelProps={{ variant: "medium" }}
            label="Kilometerstand"
            dataTestautoid="input–kmstand"
            maxLength={6}
            {...register("mileage", {
                required: "Du må fylle inn kilometerstand",
                pattern: {
                    value: /^[0-9]*$/,
                    message: "Kilometerstand kan kun inneholde tall"
                }
            })}
            errorLabel={formState.errors.mileage?.message}
            helpLabel={lastCheckedText}
        />
    );
};
