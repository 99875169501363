/**
 * En modul for å lagre preferansene til brukeren.
 *
 * Ved å bygge vårt eget datakilde objekt gjør vi det enklere å eventuelt bytte ut datakilde.
 * Siden vi bruker localstorage kommer preferansene kun til å settes på maskinen brukeren
 * bruker i det preferansen blir satt. Om vi ønsker å lagre dette på tvers av systemer
 * og nettlesere må vi vurdere å lagre det i en database i stedet.
 */

interface Source {
    get: (key: string) => string | null;
    set: (key: string, value: string) => void;
}

const source: Source = {
    get: localStorage.getItem.bind(localStorage),
    set: localStorage.setItem.bind(localStorage)
};

interface Preferences {
    prefersMenu: "side" | "mega";
    prefersMenuSetAt?: string;
}

const defaultPreferences: Preferences = {
    prefersMenu: "mega"
};

export function getPreference<K extends keyof Preferences>(preference: K) {
    const p = source.get(preference);

    if (!p) {
        return defaultPreferences[preference];
    }

    return p as Preferences[K];
}

export function setPreference<K extends keyof Preferences>(preference: K, value: Preferences[K]) {
    if (value) {
        source.set(preference, value);
    }
}
