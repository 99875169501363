import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { Accordion } from "@fremtind/jkl-accordion-react";

import "./ProductAccordions.scss";
import { ProductAccordion } from "./components/ProductAccordion";

export const ProductAccordions: React.FC<{
    products: {
        [key: string]: {
            productCode: AvtaleProduktID;
            productName: string;
        };
    };
}> = ({ products }) => {
    const productObjects = Object.values(products);

    // Vi splitter kodene i to for å kunne vise de i to kolonner med Accordions
    const firstHalf = productObjects.slice(0, Math.ceil(productObjects.length / 2));
    const secondHalf = productObjects.slice(Math.ceil(productObjects.length / 2));

    return (
        <div className="bm-purchase__selfservice__accordions jkl-spacing-xl--bottom">
            <Accordion>
                {firstHalf.map((productObject, index) => (
                    <ProductAccordion key={`${productObject.productCode}-${index}`} product={productObject} />
                ))}
            </Accordion>
            <Accordion>
                {secondHalf.map((productObject, index) => (
                    <ProductAccordion key={`${productObject.productCode}-${index}`} product={productObject} />
                ))}
            </Accordion>
        </div>
    );
};
