import { useParams } from "react-router";
import { queryClient } from "src/common/queryClient";

import { useBruker } from "@features/bruker/queries";
import { http } from "@features/core";
import type { AgreementUpdatableField, AgreementUpdateRequest, AgreementUpdateResponse } from "@model/gen";
import { useMutation, useQuery } from "@tanstack/react-query";

export type AgreementUpdateRequests = {
    organisationNumber: string;
    id: string;
    payload: AgreementUpdateRequest;
};

type AgreementMeta = {
    id: string;
    updatableFields: Array<AgreementUpdatableField>;
};

const agreementsQueryKey = {
    list: (orgnr: string, id: string) => [`avtale/meta/${orgnr}/${id}`]
};

const requestUpdatableFields = async (organisationNumber: string, agreementId: string) => {
    const data = await http.get<AgreementMeta>(`/avtale/meta/${organisationNumber}/${agreementId}`);
    return data;
};

export const useUpdatableFields = (agreementId: string) => {
    const { valgtOrganisasjon } = useBruker();

    // Denne queryen kan kun kalles fra komponenter som har valgtOrganisasjon satt, så vi kan trygt anta at den ikke vil være undefined
    return useQuery({
        queryKey: [agreementsQueryKey.list(valgtOrganisasjon!.organisasjonsnummer, agreementId)],
        queryFn: () => requestUpdatableFields(valgtOrganisasjon!.organisasjonsnummer, agreementId),
        enabled: !!valgtOrganisasjon
    });
};

export const requestUpdateAgreement = async ({ organisationNumber, id, payload }: AgreementUpdateRequests) => {
    const res = await http.put(`/avtale/${organisationNumber}/${id}`, payload);
    return res as AgreementUpdateResponse;
};

export const useRequestUpdateAgreement = () => {
    const { valgtOrganisasjon } = useBruker();
    const { agreementId } = useParams();
    return useMutation({
        mutationFn: requestUpdateAgreement,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [agreementsQueryKey.list(valgtOrganisasjon!.organisasjonsnummer, agreementId || "")]
            });
        }
    });
};
