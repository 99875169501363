import { useFormContext } from "react-hook-form";

import { PrimaryLinkButton } from "@components/Button";
import { SkjemaFooter } from "@components/SkjemaFooter";
import { Typography } from "@components/Typography";
import { formatAvstand } from "@fremtind/jkl-formatters-util";
import { ListItem, UnorderedList } from "@fremtind/jkl-list-react";

import { AgreementUpdateStep, MileageInputs } from "../Mileage";

export const Receipt: React.FC<AgreementUpdateStep> = ({ agreementId, initialValues }) => {
    const { getValues } = useFormContext<MileageInputs>();

    return (
        <div>
            <Typography className="jkl-spacing-xs--bottom" component="h2" variant="heading-3">
                Oppsummering
            </Typography>
            <UnorderedList>
                <ListItem>
                    Du har endret kilometerstand fra {formatAvstand(initialValues.mileage)} til{" "}
                    {formatAvstand(getValues("mileage"))}.
                </ListItem>
                <ListItem>Kilometerstanden kan kun endres én gang i året.</ListItem>
            </UnorderedList>
            <SkjemaFooter className="update-insurance__form__footer">
                <PrimaryLinkButton to={`/forsikringer/${agreementId}`} className="jkl-spacing-l--right">
                    Lukk
                </PrimaryLinkButton>
            </SkjemaFooter>
        </div>
    );
};
