import { useParams } from "react-router";

import { Side, SideHeader, SideInnhold } from "@components/Side";
import { AgreementUpdateFlow } from "@features/agreement-updates/flow";
import { useAgreementDetails } from "@features/agreements/agreements.queries";

export const Mileage = () => {
    const { agreementId } = useParams();

    const { data: agreement } = useAgreementDetails(agreementId || "");
    const agreementName = agreement?.objects[0].name;

    return (
        <Side>
            <SideHeader title={agreementName ? agreementName : "Oppdater avtalen"} />
            <SideInnhold className="update-insurance">
                <AgreementUpdateFlow flowType="KILOMETERSTAND" />
            </SideInnhold>
        </Side>
    );
};
