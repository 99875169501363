import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { useAgreementList } from "@features/agreements/agreements.queries";
import { useBruker } from "@features/bruker/queries";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { Side, SideHeader, SideInnhold } from "../../../components/Side";
import { FormTrackingMeta, SkjemaFlyt, Steg } from "../../../components/SkjemaFlyt";
import {
    Forside,
    Kvittering,
    RRH,
    Signer,
    SigneringFormData,
    SigneringStegProps
} from "../../../features/signering/components";
import { useGetTilbud } from "../../../features/signering/queries";
import { Trackingkey, track } from "../../../tracking";

const slides: Steg<SigneringStegProps>[] = [
    { element: Forside, stegnavn: "forside" },
    { element: RRH, stegnavn: "reelle-rettighetshavere" },
    { element: Signer, stegnavn: "signatur" },
    { element: Kvittering, stegnavn: "kvittering" }
];

export const SigneringGruppeliv = () => {
    const formMethods = useForm<SigneringFormData>();

    const avtalerQuery = useAgreementList();

    const query = useGetTilbud();

    const [meta, setMeta] = useState<FormTrackingMeta>();

    const user = useBruker().bruker;

    useEffect(() => {
        const avtaler = avtalerQuery.data?.agreements;

        if (avtaler) {
            track({
                hendelse: Trackingkey.SeTilbud,
                eksisterendeAvtaler: avtaler.map((avtale) => avtale.productCode),
                organisasjon: user?.gjeldendeOrganisasjonsnummer ?? ""
            });
        }
    }, [avtalerQuery.data?.agreements]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Side compactTextOnly>
            <SideHeader
                title="Signer tilbud"
                lead="Du har fått tilbud på gruppelivsforsikring. For å godta tilbudet må du signere med BankID."
            />
            <SideInnhold
                loadingText="Henter informasjon"
                footerProps={{ feedbackProps: { formFlowName: meta?.flowName, formStepName: meta?.stepName } }}
                query={query}
            >
                {query.isError && (
                    <ErrorMessageBox title="Det har skjedd en feil ved henting av tilbud">
                        Vennligst prøv igjen senere eller kontakt rådgiver.
                    </ErrorMessageBox>
                )}
                {query.data && (
                    <SkjemaFlyt
                        flytnavn="signering-gruppeliv"
                        setFormTrackingMeta={setMeta}
                        stegListe={slides}
                        formMethods={formMethods}
                        {...{ tilbud: query.data.entity.filter((tilbud) => !tilbud.signert) }}
                    />
                )}
            </SideInnhold>
        </Side>
    );
};
