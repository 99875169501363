import { Suspense } from "react";
import { http } from "@features/core";
import { Await, Navigate, redirect, useLoaderData } from "react-router-dom";

import { queryClient } from "src/common/queryClient";

import { Side, SideHeader, SideInnhold } from "@components/Side";
import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import { Loader } from "@fremtind/jkl-loader-react";


export const loader = () => {
    const setKonsesjonPromise = new Promise((resolve) => {
            http.put("raadgiver/konsesjon").then(() => {
            queryClient.clear();
            queryClient.fetchQuery({ queryKey: brukerQueryKey, queryFn: fetchBruker }).then(() => {
                resolve(redirect("/hjem"));
            });
        });
    });
    return { setKonsesjonPromise };
};

export const ByttKonsesjon = () => {
    const loaderData = useLoaderData() as unknown as {
        setKonsesjonPromise: Promise<Response>;
    };

    return (
        <Side>
            <SideHeader title="Forsikringer" />
            <SideInnhold>
                <Suspense fallback={<Loader textDescription="Henter forsikringer" />}>
                    <Await resolve={loaderData.setKonsesjonPromise}>
                        <Navigate to="/hjem" />
                    </Await>
                </Suspense>
            </SideInnhold>
        </Side>
    );
};
