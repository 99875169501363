import { Link } from "react-router-dom";
import { toNorwegianDateFormat } from "src/common/formatting";

import { InfoBlock, NavCard } from "@fremtind/jkl-card-react";
import type { ClaimOverview } from "@model/gen";

import "./ClaimCard.scss";

export const ClaimCard = (props: ClaimOverview) => {
    return (
        <NavCard
            className="claim-card"
            component={Link}
            padding="24"
            to={`/skadesaker/${props.claimNumber}`}
            title={props.productName}
            description={`Skadedato: ${toNorwegianDateFormat(props.claimDate)}`}
        >
            <InfoBlock>
                <dl>
                    <div>
                        <dt>Skadenummer</dt>
                        <dd>{props.claimNumber}</dd>
                    </div>

                    {props.claimDate && (
                        <div>
                            <dt>Forsikring</dt>
                            <dd>{props.name}</dd>
                        </div>
                    )}
                </dl>
            </InfoBlock>
        </NavCard>
    );
};
