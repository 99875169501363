import { useState } from "react";

import { toBackendDateFormat } from "src/common/formatting";
import { queryClient } from "src/common/queryClient";
import { IHttpError } from "src/model/http.typer";

import { http } from "@features/core";
import { DocumentDto, InvoiceDto } from "@model/gen";
import { useQuery } from "@tanstack/react-query";

import { useBruker } from "../bruker/queries";

const dokumenterQueryKeys = {
    all: (datoFra: string, datoTil: string) => ["dokumenter", datoFra, datoTil]
};

const fakturaQueryKeys = {
    all: ["faktura"],
    allZip: ["faktura", "zip"]
};

const fetchFakturaListe = async (organisasjonsnummer: string) => {
    return await http.getEntity<InvoiceDto[]>(`faktura/${organisasjonsnummer}`);
};

const fetchDokumentListe = async (datoFra: string, datoTil: string) => {
    return await http.getEntity<DocumentDto[]>(`dokumenter/dokumentmeta/${datoFra}/${datoTil}`);
};

export const useFetchDokumentListe = (datoFra?: Date, datoTil?: Date, runQuery = false) => {
    const [shouldRunQuery, setShouldRunQuery] = useState(runQuery);

    const dagensDato = new Date();
    const fjorAaret = new Date();

    fjorAaret.setFullYear(dagensDato.getFullYear() - 1);
    if (!datoFra) {
        datoFra = fjorAaret;
    }
    if (!datoTil) {
        datoTil = dagensDato;
    }

    const query = useQuery({
        queryKey: [dokumenterQueryKeys.all(toBackendDateFormat(datoFra), toBackendDateFormat(datoTil))],
        queryFn: () => fetchDokumentListe(toBackendDateFormat(datoFra), toBackendDateFormat(datoTil)),
        staleTime: 1000 * 180,
        enabled: shouldRunQuery
    });

    return {
        documents: query.data,
        isLoadingDocuments: query.isLoading,
        error: query.error as unknown as IHttpError,
        query,
        setShouldRunQuery
    };
};

export const invoiceQuery = (organizationNumber: string) => {
    return queryClient.fetchQuery({
        queryKey: [fakturaQueryKeys.all],
        queryFn: () => fetchFakturaListe(organizationNumber),
        staleTime: Infinity
    });
};

export const useFetchFakturaListe = () => {
    const { bruker } = useBruker();

    return useQuery({
        queryKey: [fakturaQueryKeys.all],
        queryFn: () => fetchFakturaListe(bruker!.gjeldendeOrganisasjonsnummer!),
        refetchOnWindowFocus: false,
        staleTime: 1000 * 180
    });
};

export type AvtaleOrganisasjonsType = "BEDRIFT" | "FORENING_FORBUND";
