import { http } from "@features/core";
import type { GrunndataContactInfoDto } from "@model/gen";
import { useQuery } from "@tanstack/react-query";

export const grunndataContactInfoQueryKeys = {
    all: ["Kontaktinfo organisasjon"]
};

export const getGrunndataContactInfo = async () => {
    const res = await http.get<GrunndataContactInfoDto>("grunndatacontactinfo");
    return res;
};

export const useGetGrunndataContactInfo = () => {
    return useQuery({ queryKey: grunndataContactInfoQueryKeys.all, queryFn: getGrunndataContactInfo });
};
