import { BMRoute } from "src/sider";

import { ProtectedRoute } from "@components/ProtectedRoute";

import { grunndataContactInfoLoader } from "./loader";
import { GrunndataContactInfoView } from "./views/GrunndataContactInfoView";

export const contactsRoutes: BMRoute[] = [
    {
        path: "/kontaktpunkter",
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute>
                        <GrunndataContactInfoView />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Kontaktpunkter",
                loader: grunndataContactInfoLoader
            }
        ]
    }
];
