import { FocusEventHandler } from "react";

import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useFormContext } from "react-hook-form";
import { isDefined } from "src/common/utils";
import { useScreen } from "src/context/UseScreenContext";

import { Typography } from "@components/Typography";
import { formatNumber, parseNumber } from "@fremtind/jkl-formatters-util";
import { WarningIcon } from "@fremtind/jkl-icons-react";
import { Loader } from "@fremtind/jkl-loader-react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@fremtind/jkl-table-react";
import { TextInput } from "@fremtind/jkl-text-input-react";

import { AvtaleTilEndring, ChangeState, EndringState, FieldValues, GruppeTilEndring } from "./EndreAntall";
import "./EndreAntallRad.scss";

type EndreAntallRadProps = {
    avtaleIndex: number;
    avtale: AvtaleTilEndring;
    editable?: boolean;
    endringerStatus?: EndringState;
    showStatus?: boolean;
    hidden?: boolean;
    onBlur?: FocusEventHandler;
};

export function EndreAntallRad(props: EndreAntallRadProps) {
    const Grupper = () => (
        <>
            {props.avtale.grupper?.map((gruppe, index) => (
                <TableRow key={gruppe.groupId}>
                    <Gruppe
                        {...props}
                        gruppe={gruppe}
                        gruppeIndex={index}
                        gruppeEndringerStatus={
                            props.endringerStatus ? props.endringerStatus[gruppe.groupId] : undefined
                        }
                    />
                </TableRow>
            ))}
        </>
    );

    const componentClassName = cx("endre-antall-skjema-tabell", {
        "endre-antall-skjema-tabell--editable": props.editable
    });
    const groupUnit = props.avtale.grupper[0].numberedValue?.unit;
    return (
        <>
            <Table collapseToList fullWidth className={componentClassName}>
                <TableHead>
                    <TableRow>
                        <TableHeader>Objekt</TableHeader>
                        <TableHeader className="capitalize">
                            {groupUnit ? groupUnit : "Antall"}
                        </TableHeader>
                        <TableHeader>Nytt antall</TableHeader>
                        {props.showStatus && <TableHeader srOnly>Status</TableHeader>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Grupper />
                </TableBody>
            </Table>
        </>
    );
}

type GruppeProps = EndreAntallRadProps & {
    gruppeIndex: number;
    gruppe: GruppeTilEndring;
    gruppeEndringerStatus?: ChangeState;
    showStatus?: boolean;
    avtale?: AvtaleTilEndring;
};

function Gruppe({
    gruppe,
    avtaleIndex,
    gruppeIndex,
    editable,
    gruppeEndringerStatus,
    showStatus,
    avtale,
    onBlur
}: GruppeProps) {
    const form = useFormContext<FieldValues>();
    const { isSmallDevice } = useScreen();

    return (
        <>
            <TableCell verticalAlign="center">
                {isSmallDevice ? (
                    <Typography
                        component="span"
                        variant="heading-3"
                        className="endre-antall-skjema-tabell__tekst-med-ikon"
                    >
                        {resolveGroupName(gruppe.gruppenavn, gruppe.groupId, avtale.grupper.length)}
                    </Typography>
                ) : (
                    <Typography component="span" variant="body" className="endre-antall-skjema-tabell__tekst-med-ikon">
                        {resolveGroupName(gruppe.gruppenavn, gruppe.groupId, avtale.grupper.length)}
                    </Typography>
                )}
            </TableCell>
            <TableCell
                verticalAlign="center"
                className="capitalize"
                data-th={gruppe?.numberedValue?.unit ? gruppe.numberedValue.unit : "Antall"}
            >
                {formatNumber(gruppe.antall)}
            </TableCell>
            <TableCell verticalAlign="center" data-th="Nytt antall">
                {editable ? (
                    <TextInput
                        label="Ny verdi"
                        labelProps={{ srOnly: true }}
                        placeholder="Ny verdi"
                        {...form.register(`avtaler.${avtaleIndex}.grupper.${gruppeIndex}.newNumber`, {
                            validate(val) {
                                return validateAntall(val, avtale.antallType);
                            },
                            onBlur
                        })}
                        errorLabel={
                            form.formState.errors.avtaler?.[avtaleIndex]?.grupper?.[gruppeIndex]?.newNumber?.message
                        }
                    />
                ) : (
                    formatNumber(gruppe.newNumber ?? "")
                )}
            </TableCell>
            {showStatus && (
                <AnimatePresence mode="wait">
                    <TableCell
                        align="right"
                        className={`endre-antall-skjema-tabell__status-kolonne ${
                            gruppeEndringerStatus === "PENDING"
                                ? "endre-antall-skjema-tabell__status-kolonne--loading"
                                : ""
                        }`}
                    >
                        <motion.span
                            key={gruppeEndringerStatus}
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.15 }}
                        >
                            {gruppeEndringerStatus === "PENDING" ? (
                                <Loader textDescription="Lagrer" variant="small" />
                            ) : gruppeEndringerStatus === "REJECTED" ? (
                                resolveFeedback(false)
                            ) : gruppeEndringerStatus === "RESOLVED" ? (
                                resolveFeedback(true)
                            ) : null}
                        </motion.span>
                    </TableCell>
                </AnimatePresence>
            )}
        </>
    );
}

function resolveFeedback(status: boolean) {
    switch (status) {
        case false: {
            return (
                <span>
                    <WarningIcon
                    className="inline-block align-middle -mt-4 mr-8"
                    />
                    <Typography
                    className="inline-block align-middle"
                    variant="body"
                    >
                        Feilet
                    </Typography>
                </span>
            );
        }
        case true: {
            return "Fullført";
        }
        default:
            return null;
    }
}

export function resolveGroupName(groupName: string | undefined, groupCount: string, avtaleTotaltAntall: number) {
    if (groupName) {
        return groupName;
    } else if (groupCount && avtaleTotaltAntall) {
        return `Objekt ${groupCount} av ${avtaleTotaltAntall}`;
    } else {
        return "Objekt";
    }
}

function validateAntall(val: number | string | undefined, valueType: AvtaleTilEndring["antallType"]) {
    if (val === undefined || val === "") {
        return undefined;
    }

    const numberVal = parseNumber(val);

    if (Number.isNaN(numberVal)) {
        return "Må bestå av tall";
    }

    if (Number(numberVal) > 99999) {
        return "Maks 99 999 per objekt";
    }

    if (isDefined(val)) {
        if (valueType === "ANSATTE") {
            // antall
            if (numberVal % 1 !== 0) {
                return "Antall kan ikke ha desimaler.";
            } else if (numberVal < 1) {
                return "Antall kan ikke være lavere enn 1.";
            }
        } else {
            // årsverk
            if (numberVal < 1) {
                return "Antall årsverk kan ikke være lavere enn 1.";
            } else if (Math.floor(numberVal) !== numberVal && numberVal.toString().split(".")[1].length > 1) {
                return "Antall årsverk kan ikke inneholde mer enn 1 desimal";
            }
        }
    }

    return undefined;
}
