import { Side, SideHeader, SideInnhold } from "@components/Side";
import { useTasks } from "@features/tasks/tasks.queries";

export function TasksOverview() {
    const tasks = useTasks().data;

    return (
        <Side>
            <SideHeader title="Varslinger" />
            <SideInnhold>
                {tasks && (
                    <ul>
                        {tasks.map((task) => (
                            <li key={task.taskId}>{task.title}</li>
                        ))}
                    </ul>
                )}
            </SideInnhold>
        </Side>
    );
}
