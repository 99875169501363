import { Flag } from "./featureflag.model";
import { validateFeatureflag } from "./validateFeatureflag";
import { validateLocalStorageFlag } from "./validateLocalStorageFlag";

export const useFeatureflag = (flag: Flag | undefined) => {
    const isLocalStorageFlag = validateLocalStorageFlag(flag);
    const isConfigFlag = validateFeatureflag(flag);

    return isLocalStorageFlag || isConfigFlag;
};
