import { http } from "@features/core";
import { OpprettPersonTilgangerRequest, OpprettTredjepartsTilgangerRequest } from "@model/gen";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Rolle } from "../../model/Rolle";
import { IHttpError } from "../../model/http.typer";
import { Tilganger } from "./models";

export const tilgangerQueryKeys = {
    all: ["tilganger"]
};

const getTilganger = async () => {
    const res = await http.get<Tilganger>("tilganger");
    return res;
};

export const useGetTilganger = () => {
    return useQuery({ queryKey: tilgangerQueryKeys.all, queryFn: getTilganger });
};

interface PutTilgangerArgs {
    id: string;
    data: { roller: Rolle[] };
    bedrift?: boolean;
}

const putTilganger = async ({ id, data, bedrift = false }: PutTilgangerArgs) => {
    const res = await http.put<Tilganger>(`tilganger/${bedrift ? "organisasjon/" : ""}${id}`, data);

    return res;
};

export const usePutTilganger = () => {
    return useMutation<Tilganger, IHttpError, PutTilgangerArgs>({ mutationFn: putTilganger });
};

const deleteTilganger = async ({ id, bedrift = false }: { id: string; bedrift?: boolean }) => {
    const res = await http.del<Tilganger>(`tilganger/${bedrift ? "organisasjon/" : ""}${id}`);
    return res;
};

export const useDeleteTilganger = () => {
    return useMutation<any, IHttpError, { id: string; bedrift?: boolean }>({ mutationFn: deleteTilganger });
};

interface PostTilgangerArgs {
    data: (Omit<OpprettPersonTilgangerRequest, "roller"> | Omit<OpprettTredjepartsTilgangerRequest, "roller">) & {
        roller: Rolle[];
    };
}

const postTilganger = async ({ data }: PostTilgangerArgs) => {
    const isBedrift = "fraOrganisasjon" in data;
    const res = await http.post<Tilganger>(`tilganger${isBedrift ? "/organisasjoner" : ""}`, data);

    return res;
};

export const usePostTilganger = () => {
    const queryClient = useQueryClient();

    return useMutation<Tilganger, IHttpError, PostTilgangerArgs>({
        mutationFn: postTilganger,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: tilgangerQueryKeys.all });
        }
    });
};
