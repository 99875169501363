import { Suspense } from "react";

import { Await, useLoaderData } from "react-router-dom";
import { LenkeId, useExternalLinks } from "src/common/lenker";
import { AgreementOverviewResponse } from "@model/gen";
import { AnsattbrosjyreButton } from "src/sider/forsikringer/components/AnsattbrosjyreButton";
import { Trackingkey, track } from "src/tracking";

import { ExcelExportLink } from "@components/ExcelExportLink";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { NavLink } from "@fremtind/jkl-core";
import { Loader } from "@fremtind/jkl-loader-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import "./AgreementsOverview.scss";
import { Forsikringer } from "./AgreementsOverviewComposer";

const ErrorBoundary = () => {
    return (
        <>
            <SideHeader title="Dine forsikringer" />
            <SideInnhold>
                <ErrorMessageBox title="Oi, litt tekniske problemer her">
                    Vi klarer ikke hente avtalene dine. Prøv en gang til eller kom tilbake senere.
                </ErrorMessageBox>
            </SideInnhold>
        </>
    );
};

export const AgreementsOverview = () => {
    const { agreementOverviewResponse } = useLoaderData() as { agreementOverviewResponse: AgreementOverviewResponse };
    const { getLinkByLinkId } = useExternalLinks();
    const privatForsikringLink = getLinkByLinkId(LenkeId.privatforsikring)?.href;
    const meldSkadeLink = getLinkByLinkId(LenkeId.meldSkade);

    return (
        <Side className="agreement-overview">
            <Suspense
                fallback={<Loader className={"agreement-overview__loader"} textDescription="Henter forsikringer" />}
            >
                <Await resolve={agreementOverviewResponse} errorElement={<ErrorBoundary />}>
                    {(data: AgreementOverviewResponse) => (
                        <>
                            <SideHeader
                                actions={
                                    data.agreements?.length > 0 && (
                                        <>
                                            {meldSkadeLink && (
                                                <a
                                                    href={meldSkadeLink.href}
                                                    className="jkl-button jkl-button--primary"
                                                    data-density="compact"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() => {
                                                        track({
                                                            hendelse: Trackingkey.LenkeTrykk,
                                                            lenkeId: "meld-skade"
                                                        });
                                                    }}
                                                >
                                                    {meldSkadeLink.label}
                                                </a>
                                            )}
                                            <AnsattbrosjyreButton />
                                            <ExcelExportLink export="avtaleoversikt" />
                                        </>
                                    )
                                }
                                title="Dine forsikringer"
                            />

                            <SideInnhold hideFooter={data.agreements?.length < 1}>
                                <>
                                    <Forsikringer
                                        agreements={data.agreements ?? []}
                                        errorMessages={data.clientErrorMessages}
                                    />
                                    {privatForsikringLink && (
                                        <NavLink
                                            href={privatForsikringLink}
                                            target="_blank"
                                            onClick={() => {
                                                track({
                                                    hendelse: Trackingkey.LenkeTrykk,
                                                    lenkeId: "forsikringsoversikt privatforsikring"
                                                });
                                            }}
                                        >
                                            Gå til dine privatforsikringer
                                        </NavLink>
                                    )}
                                </>
                            </SideInnhold>
                        </>
                    )}
                </Await>
            </Suspense>
        </Side>
    );
};
