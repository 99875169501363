const fornavn = [
    "Lillegull",
    "Mæhmed",
    "Lomeihjel",
    "Per Anton",
    "Bendik",
    "Anja",
    "Gunvor",
    "Hanne",
    "Rune",
    "Fredrik",
    "Thomas",
    "Tanja",
    "Jo Emil",
    "Frida",
    "Joachim",
    "Stian",
    "Stig",
    "Eirik",
    "Per Kari",
    "Odd Stine",
    "Bjart Anne",
    "Monica",
    "Jøran",
    "Tor Mikkel",
    "Sylvi",
    "Per-Willy",
    "Anders",
    "Grete",
    "Knut",
    "Odd Einar"
];

const etternavn = [
    "Mæland",
    "Kallmyr",
    "Wara",
    "Listhaug",
    "Amundsen",
    "Anundsen",
    "Faremo",
    "Storberget",
    "Dørum",
    "Harlem",
    "Stumpen",
    "Kråkesølv",
    "Stenersen",
    "Grusen",
    "Granitten",
    "Leirstein",
    "Marmorsen",
    "Gneis",
    "Gabbro",
    "Gips",
    "Flint",
    "S. Vindler"
];

export function randomMixpanelName() {
    return {
        fornavn: fornavn[Math.floor(Math.random() * fornavn.length)],
        etternavn: etternavn[Math.floor(Math.random() * etternavn.length)]
    };
}
