import { Flag } from "./featureflag.model";

export const validateLocalStorageFlag = (flag: Flag | undefined) => {
    if (flag === undefined) {
        return false;
    }

    const flagValue = localStorage.getItem(flag.toString());

    return flagValue === "true";
};
