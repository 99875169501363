import { type AgreementDetails } from "@model/gen";

export const useAgreementField = (agreementDetails: AgreementDetails) => {
    const { fields, coverageTable } = agreementDetails.objects[0];

    const milage = `${fields.find((field) => field.description?.toLowerCase() === "kilometerstand" || field.description?.toLowerCase() === "km.stand")?.value.replace(/\D/g, "")} km`;
    const leasing = Boolean(fields.find((field) => field.description === "Leasing")?.value);
    const bonus = fields.find((field) => field.description === "Bonus")?.value;
    const registrationNumber = fields.find((field) => field.description === "Registreringsnummer")?.value;
    const lastUpdated = fields.find((field) => field.description === "Siste avlesning")?.value;
    const usageType = fields.find((field) => field.description === "Bruksområde")?.value;

    const coverage = coverageTable.rows.map(({ row }) => row.map((cell) => cell.value)[0])[0];

    const car = {
        milage,
        leasing,
        bonus,
        registrationNumber,
        lastUpdated,
        usageType,
        coverage
    };

    const maxKilometer = Number(
        fields
            .find((field) => field.description === "Kjørelengde")
            ?.value.split(" km")[0]
            .replace(/\D/g, "")
    );

    return { car, maxKilometer };
};
