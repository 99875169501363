import { useTasks } from "@features/tasks/tasks.queries";
import type { AgreementDetails as IAgreementDetails } from "@model/gen";

import {
    AgreementClaimsSection,
    AgreementDetailSection,
    AgreementDetailsDocuments,
    AgreementObjectList,
    AgreementOnHold,
    AgreementRenewal,
    AgreementTasksSection
} from "../../components";
import { SmartDelayMessage, TravelCardSection } from "../../components/AgreementSpecificComponents";
import styles from "./AgreementDetails.module.scss";

interface Props {
    agreementDetails: IAgreementDetails;
}

export const AgreementDetails = ({ agreementDetails }: Props) => {
    const tasks = useTasks();

    const filteredTasks = tasks.data?.filter(
        (task) => task.agreementNumber === agreementDetails.agreementId && !task.subjects?.includes("Fornyelse")
    );

    return (
        <>
            <AgreementRenewal agreementDetails={agreementDetails} />
            <AgreementOnHold agreementDetails={agreementDetails} />
            <div className={styles.detailsWrapper}>
                <AgreementDetailSection agreementDetails={agreementDetails} />
                <AgreementClaimsSection agreementDetails={agreementDetails} />
                <SmartDelayMessage agreementDetails={agreementDetails} />
                <AgreementDetailsDocuments agreement={agreementDetails} />
                {filteredTasks && filteredTasks.length > 0 && <AgreementTasksSection agreementTasks={filteredTasks} />}
                <TravelCardSection agreementDetails={agreementDetails} />
                <AgreementObjectList agreementDetails={agreementDetails} />
            </div>
        </>
    );
};
