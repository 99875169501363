import { useContext } from "react";

import { Side, SideHeader, SideInnhold } from "src/components/Side";
import ThemeContext from "src/context/ThemeContext";

import { RelatedServicesList } from "@components/RelatedServicesList/RelatedServicesList";
import { Typography } from "@components/Typography";
import { useBruker } from "@features/bruker/queries";
import { formatTelefonnummer } from "@fremtind/jkl-formatters-util";

import "./Kontakt.scss";
import { Faq } from "./components/Faq";
import { KontaktCard } from "./components/KontaktCard";

export function Kontakt() {
    const { bruker } = useBruker();
    const raadgiverInfo = bruker?.radgiverKontaktInfo;
    const navn = bruker?.radgiverKontaktInfo?.navn;
    const bank = bruker?.radgiverKontaktInfo?.arbeidssted;
    const mobil = bruker?.radgiverKontaktInfo?.mobil;
    const formattedNr = mobil && formatTelefonnummer(mobil, { countryCode: "47" });
    const epost = bruker?.radgiverKontaktInfo?.epost;
    const { distributor } = useContext(ThemeContext);

    const kontaktinfoingress =
        navn && bank
            ? `Rådgiveren din er ${navn} hos ${bank}.`
            : navn
              ? `Rådgiveren din er ${navn}.`
              : bank
                ? `Vi klarte ikke å hente kontaktinformasjonen til rådgiveren din i systemene våre. Du finner den i nettbanken hos ${bank}.`
                : raadgiverInfo?.beskrivelse
                  ? raadgiverInfo.beskrivelse
                  : "Vi ser ikke hvem rådgiveren din er. Dersom du er bankkunde finner du kontaktinformasjon i din nettbank.";

    return (
        <Side>
            <SideHeader title="Kontakt oss" />
            <SideInnhold className="contact-page">
                <div className="contact-info">
                    {distributor === "dnb" ? (
                        <div className="contact-info__overview">
                            <Typography className="jkl-spacing-xs--bottom" variant="heading-2">
                                DNB Bedriftssenter
                            </Typography>
                            <Typography className="jkl-spacing-l--bottom">
                                DNB Bedriftssenter kan gi deg rådgiving og hjelp til å gjøre nødvendige endringer på
                                forsikringene dine.
                            </Typography>
                            <div className="kontakt-cards">
                                <KontaktCard btnTxt={"Mobil"} mobil={"+47 91 50 48 00"} />
                                <KontaktCard btnTxt={"E-post"} epost={"forsikring.bedrift@dnb.no"} />
                            </div>
                        </div>
                    ) : distributor === "sb1" ? (
                        raadgiverInfo ? (
                            <>
                                <div className="contact-info__overview">
                                    <Typography className="jkl-spacing-xs--bottom" variant="heading-2">
                                        {raadgiverInfo.beskrivelse ? "Megler" : "Rådgiver"}
                                    </Typography>

                                    <Typography className="jkl-spacing-l--bottom">{kontaktinfoingress}</Typography>
                                </div>
                                <div className="kontakt-cards">
                                    {formattedNr && <KontaktCard mobil={formattedNr} />}
                                    {epost && <KontaktCard epost={epost} />}
                                </div>
                            </>
                        ) : (
                            <Typography>{kontaktinfoingress}</Typography>
                        )
                    ) : null}
                </div>
                <section className="faq">
                    <Typography variant="heading-3" className="jkl-spacing-l--bottom">
                        Ofte stilte spørsmål
                    </Typography>
                    <Faq />
                </section>
                <section className="related-links">
                    <Typography variant="heading-3" id="relaterte-tjenester">
                        Relaterte tjenester
                    </Typography>
                    <RelatedServicesList />
                </section>
            </SideInnhold>
        </Side>
    );
}
