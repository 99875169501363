import { Link } from "@fremtind/jkl-core";
import { DocumentDto, InvoiceDto } from "@model/gen";

import { Typography } from "../../../../components/Typography";

interface Props {
    invoice: InvoiceDto;
    documents?: DocumentDto[];
}

export const InvoiceDocuments = ({ documents }: Props) => {
    if (!documents?.length) {
        return null;
    }

    return (
        <>
            <Typography component="h2" variant="heading-3">
                Dokumenter
            </Typography>
            <ul className="bm-invoice-details__document-list">
                {documents.map((document) => (
                    <li key={document.id}>
                        <Link href={`/bedrift/api/dokumenter/document/${document.id}`} external target="_blank">
                            {document.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    );
};
