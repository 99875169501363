// dimport { useState } from "react";
import { Dialog } from "@components/Dialog";
import { FeedbackProps } from "@components/GlobalFeedback";
import { ModalInstance } from "@fremtind/jkl-modal-react";
import { AgreementDetails } from "@model/gen";

import { useEndreAvtaledetaljer } from "./agreementchanges.queries";
import { SkjemaKontroller } from "./forms/SkjemaKontroller";

interface Props {
    avtaleDetaljer: AgreementDetails;
    dialogRef: ModalInstance;
    chosenObject?: string;
    feedbackProps?: FeedbackProps;
    formAction: "change" | "cancel";
    formType: "standard" | "car";
    mutation?: ReturnType<typeof useEndreAvtaledetaljer>;
}

export const AgreementChangesComposer = ({
    dialogRef,
    avtaleDetaljer,
    formAction,
    feedbackProps,
    chosenObject,
    formType,
    mutation
}: Props) => {
    return (
        <Dialog
            dialogRef={(instance) => {
                dialogRef.current = instance;
            }}
            title=""
            onConfirm={() => {
                dialogRef.current?.hide();
            }}
            onCancel={() => {
                dialogRef.current?.hide();
            }}
        >
            <SkjemaKontroller
                dialogRef={dialogRef}
                avtaleDetaljer={avtaleDetaljer}
                feecbackProps={feedbackProps}
                chosenObject={chosenObject}
                formAction={formAction}
                formType={formType}
                mutation={mutation}
                onTilbake={function (): void {
                    throw new Error("Function not implemented.");
                }}
                onFrem={function (): void {
                    throw new Error("Function not implemented.");
                }}
                onFerdig={function (): void {
                    throw new Error("Function not implemented.");
                }}
                onAvbryt={function (): void {
                    throw new Error("Function not implemented.");
                }}
                onRestart={function (): void {
                    throw new Error("Function not implemented.");
                }}
            />
        </Dialog>
    );
};
