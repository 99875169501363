import { ChangeEvent, FocusEvent, useEffect, useState } from "react";

import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { FormState, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { model, queries } from "@features/navneliste";
import { PrimaryButton, SecondaryButton } from "@fremtind/jkl-button-react";
import { DatePicker, formatInput, parseDateString } from "@fremtind/jkl-datepicker-react";
import { formatValuta, registerWithMasks } from "@fremtind/jkl-formatters-util";
import { ErrorMessageBox, FormErrorMessageBox, WarningMessageBox } from "@fremtind/jkl-message-box-react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@fremtind/jkl-table-react";
import { TextInput } from "@fremtind/jkl-text-input-react";
import { useQueryClient } from "@tanstack/react-query";

import { EndreSykelonnFormState, EndreSykelonnStegProps } from ".";
import { asDate, toBackendDateFormat } from "../../../../common/formatting";
import { isDefined, useGVerdi } from "../../../../common/utils";
import { SecondaryLinkButton } from "../../../../components/Button";
import { Confirm } from "../../../../components/Confirm";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Typography } from "../../../../components/Typography";
import { useScreen } from "../../../../context/UseScreenContext";
import { Trackingkey, track } from "../../../../tracking";

type HoyLav = "hoy" | "lav";

interface FormRowProps {
    ansatt: model.Person;
    onTilbake: () => void;
    onWarn: (arg: HoyLav, gValue: number) => void;
    clearWarn: (arg: HoyLav) => void;
}

const errorMessages = {
    fraDato: "Gyldig fra",
    lonn: "Ny årslønn"
};

const numVal = (val: string) => Number(val.replaceAll(/\s/g, ""));

const FormRow = ({ ansatt, onTilbake, onWarn, clearWarn }: FormRowProps) => {
    const form = useFormContext<EndreSykelonnFormState>();
    const { register, formState, setValue, getValues, unregister } = form;

    const { registerWithNumber } = registerWithMasks<EndreSykelonnFormState>(form);

    const { errors } = formState;
    const { isSmallDevice } = useScreen();
    const gVerdi = useGVerdi();

    const sykelonnAvtale = ansatt.avtaler.find((avtale) => avtale.produktkode === AvtaleProduktID.SYKELONN);

    const removeSelfFromList = () => {
        const newAnsattIndekser = getValues("ansatteIndekser").filter((a) => a !== ansatt.indeks);
        setValue("ansatteIndekser", newAnsattIndekser);

        unregister(`ansatte.${ansatt.indeks}`);

        if (newAnsattIndekser.length === 0) {
            onTilbake();
        }
    };

    return (
        <TableRow>
            <TableCell data-th="Navn" className="sykelonn-form__table-cell sykelonn-form__table-cell--text">
                {ansatt.fornavn} {ansatt.etternavn}
                <br />
                <Typography component="span" variant="small">
                    {ansatt.fodselsnummer}
                </Typography>
            </TableCell>
            <TableCell data-th="Lønn" className="sykelonn-form__table-cell sykelonn-form__table-cell--text">
                {formatValuta(sykelonnAvtale?.arslonn ?? "Ukjent")}
            </TableCell>
            <TableCell data-th="Ny årslønn" className="sykelonn-form__table-cell">
                <TextInput
                    label="Ny årslønn"
                    labelProps={{ srOnly: true }}
                    className="sykelonn-form__lonn-input"
                    {...registerWithNumber(`ansatte.${ansatt.indeks}.lonn`, {
                        required: "Du må fylle inn lønn.",
                        validate: (lonn) => {
                            if (Number.isNaN(numVal(lonn as string))) {
                                return "Lønn må være et tall.";
                            }

                            return true;
                        },
                        onBlur: (e: FocusEvent<HTMLInputElement>) => {
                            if (e.target.value === "") {
                                clearWarn("lav");
                                return;
                            }

                            const valueAsNumber = numVal(e.target.value);
                            if (valueAsNumber <= gVerdi(6)) {
                                onWarn("lav", 6);
                            } else if (valueAsNumber >= gVerdi(sykelonnAvtale?.maksG ?? 12)) {
                                onWarn("hoy", sykelonnAvtale?.maksG ?? 12);
                            }
                        },
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                            const numVal = Number(e.target.value.replaceAll(/\s/g, ""));

                            if (numVal > gVerdi(6)) {
                                clearWarn("lav");
                            }

                            if (numVal < gVerdi(sykelonnAvtale?.maksG ?? 12)) {
                                clearWarn("hoy");
                            }
                        }
                    })}
                    align="left"
                    autoComplete="off"
                    errorLabel={errors.ansatte?.[ansatt.indeks]?.["lonn"]?.message}
                />
            </TableCell>
            <TableCell data-th="Gyldig fra" className="sykelonn-form__table-cell max-w-[200px]">
                <DatePicker
                    label="Gyldig fra"
                    {...register(`ansatte.${ansatt.indeks}.fraDato`, {
                        validate: (gyldigFraDato: any) => {
                            const _gyldigFraDato = parseDateString(gyldigFraDato as unknown as string | undefined);

                            if (!_gyldigFraDato) {
                                return "Du må velge en gyldig dato.";
                            }

                            const errorMessage =
                                "Dato kan ikke være etter dagens dato, før avtalens startdato eller før ansettelsesdato.";

                            if (isAfter(endOfDay(_gyldigFraDato), endOfDay(new Date()))) {
                                return errorMessage;
                            } else if (
                                sykelonnAvtale?.kanEndresFra &&
                                isBefore(startOfDay(_gyldigFraDato), startOfDay(asDate(sykelonnAvtale.kanEndresFra)))
                            ) {
                                return errorMessage;
                            }

                            return true;
                        },
                        required: "Du må velge dato."
                    })}
                    errorLabel={errors.ansatte?.[ansatt.indeks]?.fraDato?.message}
                    labelProps={{ srOnly: true }}
                    disableAfterDate={formatInput(new Date())}
                    disableBeforeDate={formatInput(asDate(sykelonnAvtale!.kanEndresFra!))}
                />
            </TableCell>
            <TableCell
                align="right"
                width="250px"
                className="sykelonn-form__table-cell sykelonn-form__table-cell--action"
            >
                <Confirm
                    label={isSmallDevice ? "Fjern person" : "Fjern"}
                    onConfirm={() => {
                        removeSelfFromList();
                    }}
                />
            </TableCell>
        </TableRow>
    );
};

// kalkuler hvilke feilmeldinger som skal vises i oppsummeringen basert på form state`
const useSimpleErrorList = (formState: FormState<EndreSykelonnFormState>) => {
    const ansatteErrors = formState.errors.ansatte;

    if (!ansatteErrors) {
        return [];
    }

    const errs = Object.entries(errorMessages).map(([fieldName, errorMessage]) => {
        if (Object.values(ansatteErrors).some((error) => fieldName in (error ?? {}))) {
            return errorMessage;
        }

        return undefined;
    });

    return errs.filter(isDefined);
};

export const EndreLonn = ({ ansatte, onFrem, onTilbake, onAvbryt, mutation }: EndreSykelonnStegProps) => {
    const { watch, handleSubmit, formState, clearErrors } = useFormContext<EndreSykelonnFormState>();
    const { indeks } = useParams<"indeks">();
    const queryClient = useQueryClient();

    const [technicalIssues, setTechnicalIssues] = useState(false);
    const [warnings, setWarnings] = useState<Array<{ ansattIndeks: string; warningType: HoyLav; gValue: number }>>([]);

    const simpleErrorList = useSimpleErrorList(formState);

    const chosenAnsatte = ansatte.filter((ansatt) => watch("ansatteIndekser")?.includes(ansatt.indeks)) ?? [];

    const onSubmit = (formData: EndreSykelonnFormState) => {
        const nyLonn = Object.entries(formData.ansatte).map(([ansattIndeks, data]) => {
            const ansatt: model.Person | undefined = chosenAnsatte.find((a) => a.indeks === ansattIndeks);

            return {
                indeks: ansattIndeks,
                avtaleId: ansatt!.avtaler.find((avtale) => avtale.produktkode === AvtaleProduktID.SYKELONN)!.avtaleId,
                lonn: Number(data.lonn),
                fraDato: toBackendDateFormat(data.fraDato!)
            };
        });

        mutation.mutate(nyLonn, {
            onSuccess: () => {
                nyLonn.forEach((ansatt) => {
                    track({
                        hendelse: Trackingkey.Endre,
                        type: "endre sykelønn",
                        tidligereVerdi: ansatte
                            .find((a) => a.indeks === ansatt.indeks)!
                            .avtaler.find((avtale) => avtale.produktkode === AvtaleProduktID.SYKELONN)!.arslonn,
                        nyVerdi: ansatt.lonn,
                        avtale: "Z12"
                    });
                });

                queryClient.invalidateQueries({ queryKey: queries.ansatt.queryKey.all });
                onFrem();
            },
            onError: () => {
                setTechnicalIssues(true);
            }
        });
    };

    useEffect(() => {
        if (formState.errors.ansatte) {
            clearErrors();
        }
        // Fjern alle errors om ansatte har en feilmelding på mount. Dette kan henge igjen fra forrige slide
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * oppdaterer listen med warnings til å inneholde et nytt innslag
     *
     * action: sier om verdien er under minimum G eller høyere enn maksimum G
     * gValue: bestemmer selve G-verdien, som brukes i feilmeldingen
     */
    const setWarning = (ansattIndeks: string) => (action: HoyLav, gValue: number) => {
        setWarnings([...warnings, { ansattIndeks, warningType: action, gValue }]);
    };

    // fjerner en warning basert på om den er høy eller lav
    const handleClearWarn = (ansattIndeks: string) => (action: HoyLav) => {
        setWarnings((prev) =>
            prev.filter((warn) => {
                if (warn.ansattIndeks !== ansattIndeks) {
                    return true;
                }

                if (warn.warningType !== action) {
                    return true;
                }

                return false;
            })
        );
    };

    const showLonnBelowMinimumGWarning = warnings.some((warning) => warning.warningType === "lav");
    const showLonnAboveMaxGWarning = warnings.find((warning) => warning.warningType === "hoy")?.gValue;

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="sykelonn-form">
            <div className="sykelonn-form__header">
                <div>
                    <Typography variant="heading-3" component="h2">
                        Ny årslønn
                    </Typography>
                    <Typography>Årslønnen du fyller inn må være den ansattes utbetalte lønn før skatt.</Typography>
                </div>
            </div>

            <Table fullWidth collapseToList className="sykelonn-form__table">
                <TableHead>
                    <TableRow>
                        <TableHeader density="compact" bold={false}>
                            Navn
                        </TableHeader>
                        <TableHeader density="compact" bold={false}>
                            Årslønn
                        </TableHeader>
                        <TableHeader density="compact" bold={false}>
                            Ny årslønn
                        </TableHeader>
                        <TableHeader density="compact" bold={false}>
                            Gyldig fra
                        </TableHeader>
                        <TableHeader srOnly>Fjern</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {chosenAnsatte.map((ansatt) => (
                        <FormRow
                            key={ansatt.indeks}
                            ansatt={ansatt}
                            onTilbake={onTilbake}
                            clearWarn={handleClearWarn(ansatt.indeks)}
                            onWarn={setWarning(ansatt.indeks)}
                        />
                    ))}
                </TableBody>
            </Table>
            {showLonnBelowMinimumGWarning && showLonnAboveMaxGWarning ? (
                <WarningMessageBox className="sykelonn-form__message-box" title="Lønn">
                    NAV dekker lønn opptil 6G og dermed trenger ikke bedriften å forsikre ansatte med lønn under 6G i
                    Sykelønnsforsikringen.
                    <br />
                    <br />
                    {`Avtalen din dekker ikke lønn over ${showLonnAboveMaxGWarning}G. Ønsker du å endre dette ta kontakt med rådgiver.`}
                </WarningMessageBox>
            ) : showLonnBelowMinimumGWarning ? (
                <WarningMessageBox className="sykelonn-form__message-box" title="Lav lønn">
                    NAV dekker lønn opptil 6G og dermed trenger ikke bedriften å forsikre ansatte med lønn under 6G i
                    Sykelønnsforsikringen.
                </WarningMessageBox>
            ) : showLonnAboveMaxGWarning ? (
                <WarningMessageBox className="sykelonn-form__message-box" title="Høy lønn">
                    {`Avtalen din dekker ikke lønn over ${showLonnAboveMaxGWarning}G. Ønsker du å endre dette ta kontakt med rådgiver.`}
                </WarningMessageBox>
            ) : null}
            <FormErrorMessageBox
                className="sykelonn-form__message-box"
                messageBoxProps={{ title: "Du har ikke fylt ut alle feltene riktig" }}
                isValid={simpleErrorList.length < 1}
                errors={simpleErrorList}
                isSubmitted
            />
            {technicalIssues && (
                <ErrorMessageBox title="Oi, litt tekniske problemer her!">
                    Prøv en gang til eller kom tilbake senere.
                </ErrorMessageBox>
            )}
            <SkjemaFooter>
                <PrimaryButton
                    loader={{
                        showLoader: mutation.isPending,
                        textDescription: "Oppdaterer lønn på Sykelønn"
                    }}
                >
                    Lagre endringer
                </PrimaryButton>
                {indeks ? (
                    <SecondaryLinkButton
                        to="/ansatte-navn"
                        onClick={() => {
                            onAvbryt();
                            track({
                                hendelse: Trackingkey.Skjemaflyt,
                                flytnavn: "endre-sykelønn",
                                handling: "avbryt",
                                stegnavn: "endre-lønn"
                            });
                        }}
                    >
                        Avbryt
                    </SecondaryLinkButton>
                ) : (
                    <SecondaryButton
                        role="button"
                        onClick={() => {
                            onTilbake();
                        }}
                    >
                        Tilbake
                    </SecondaryButton>
                )}
            </SkjemaFooter>
        </form>
    );
};
