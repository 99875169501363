import { Link, useParams } from "react-router-dom";

import { SideHeader } from "@components/Side";
import { useInvoiceDetails } from "@features/invoice/invoice.queries";
import { InvoiceDto } from "@model/gen";

interface Props {
    isLoading?: boolean;
}

const getTextForStatus = (invoice: InvoiceDto) => {
    switch (invoice.status) {
        case "ANNULLERT":
            return (
                <>
                    Denne fakturaen er annullert, og skal ikke betales. Utestående beløp fra denne fakturaen er overført
                    til fakturanummer{" "}
                    {invoice.replacedByInvoiceId ? (
                        <Link className="jkl-link" to={`/faktura/${invoice.replacedByInvoiceId}`}>
                            {invoice.replacedByInvoiceNumber}
                        </Link>
                    ) : (
                        invoice.replacedByInvoiceNumber
                    )}
                    .
                </>
            );
        case "BETALT":
            return "Fakturaen betales til vårt forsikringsselskap Fremtind.";
        case "DELBETALT":
            return "Du har allerede betalt deler av beløpet, «Beløp å betale» er oppdatert. Fakturaen betales til vårt forsikringsselskap Fremtind.";
        case "UBETALT":
            return "Fakturaen betales til vårt forsikringsselskap Fremtind.";
        default:
            return undefined;
    }
};

export const InvoiceDetailsHeaderComposer = ({ isLoading = false }: Props) => {
    const params = useParams<"invoiceId">();
    const { data } = useInvoiceDetails(params.invoiceId!);

    const invoice = data?.entity.invoiceDto;

    const lead = isLoading || !invoice ? undefined : getTextForStatus(invoice);

    return <SideHeader title="Fakturadetaljer" lead={lead} />;
};
