import {
    SkeletonAnimation,
    SkeletonElement,
    SkeletonTable,
    SkeletonTableHeader,
    SkeletonTableRow
} from "@fremtind/jkl-loader-react";

export const InvoiceDetailsLoader = () => {
    return (
        <SkeletonAnimation>
            <div className="bm-invoice-details">
                <div className="bm-invoice-details__header">
                    <div className="flex w-[660px] flex-col gap-64">
                        <SkeletonElement width="100%" height="72px" />
                        <SkeletonElement width="100%" height="72px" />
                        <SkeletonElement width="100%" height="72px" />
                        <SkeletonElement width="100%" height="72px" />
                    </div>
                    <div className="bm-invoice-details__header__details">
                        {Array.from({ length: 4 }).map((_, i) => (
                            <div key={i} className="flex flex-col gap-8">
                                <SkeletonElement width="120px" height="24px" />
                                <SkeletonElement width="180px" height="32px" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="bm-invoice-details__content">
                    <SkeletonTable>
                        <SkeletonTableHeader>
                            <div className="w-[115px]">
                                <SkeletonElement width={115} height={16} />
                            </div>
                            <div className="w-[250px]">
                                <SkeletonElement width={115} height={16} />
                            </div>
                            <div className="w-[115px]">
                                <SkeletonElement width={115} height={16} />
                            </div>
                            <div className="w-[115px]">
                                <SkeletonElement width={115} height={16} />
                            </div>
                            <div className="w-[115px]">
                                <SkeletonElement width={115} height={16} />
                            </div>
                        </SkeletonTableHeader>
                        {Array.from({ length: 5 }).map((_, i) => (
                            <SkeletonTableRow key={i}>
                                <div className="w-[115px]">
                                    <SkeletonElement width={160} height={24} />
                                </div>
                                <div className="w-[250px]">
                                    <SkeletonElement width={i % 2 ? 115 : 185} height={19} />
                                </div>
                                <div className="w-[115px]">
                                    <SkeletonElement width={102} height={24} />
                                </div>
                                <div className="w-[115px]">
                                    <SkeletonElement width={102} height={24} />
                                </div>
                                <div className="w-[115px]">
                                    <SkeletonElement width={80} height={24} />
                                </div>
                            </SkeletonTableRow>
                        ))}
                    </SkeletonTable>
                </div>
            </div>
        </SkeletonAnimation>
    );
};
