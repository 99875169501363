import { IndexRouteObject, NonIndexRouteObject, useRouteError } from "react-router-dom";

import { Feil as HttpFeilSide } from "@components/Feil";
import { agreementRoutes } from "@features/agreements/agreements.routes";
import { antallslisteRoutes } from "@features/antallsliste";
import { claimsRoutes } from "@features/claims";
import { contactsRoutes } from "@features/grunndata-contact-info";
import { invoiceRoutes } from "@features/invoice";
import { kjopRoutes } from "@features/kjop";
import { organisasjonRoutes } from "@features/organisasjon";
import { tasksRoutes } from "@features/tasks/tasks.routes";
import { ttpRoutes } from "@features/tilganger-tredje-part/routes";

import App from "../App";
import { Root } from "../Root";
import { FilVisningsFeil } from "../components/Feil/FilVisningsFeil/FilVisningsFeil";
import { IkkeFunnetFeil } from "../components/Feil/IkkeFunnetFeil/IkkeFunnetFeil";
import { ProtectedRoute } from "../components/ProtectedRoute";
import { NavnelisteOversiktSide } from "../features/navneliste/components/NavnelisteOversiktSide";
import { LeggTilBedrift } from "../features/tilganger/components/TilgangerLeggTilBedrift";
import { LeggTilPerson } from "../features/tilganger/components/TilgangerLeggTilSkjema";
import { BrukerRolle, Organisasjon } from "../model/bruker.typer";
import { IHttpError } from "../model/http.typer";
import { Distributor } from "../model/theme.typer";
import { AnsattEndreSykelonn } from "./AnsattEndreSykelonn";
import { AnsattInnmelding } from "./AnsattInnmelding";
import { AnsattUtmelding } from "./AnsattUtmelding";
import { DokumentOversikt } from "./DokumentOversikt";
import { Feil } from "./Feil";
import { LoggUt } from "./LoggUt";
import { MedlemInnmelding } from "./MedlemInnmelding";
import { Medlemmer } from "./MedlemOversikt";
import { MedlemUtmelding } from "./MedlemUtmelding";
import { Ansattbrosjyre } from "./ansattbrosjyre";
import { Home } from "./hjem";
import { Kontakt } from "./kontakt";
import { ReelleRettighetshavere } from "./reelle-rettighetshavere/ReelleRettighetshavere";
import Samtykke from "./samtykke/Samtykke";
import { SigneringGruppeliv } from "./signering/gruppeliv/SigneringGruppeliv";
import { TilgangerOversikt } from "./tilganger/TilgangerOversikt";
import { TilgangerLeggTil } from "./tilganger/gi-tilgang/TilgangerLeggTil";

interface BreadCrumbMeta {
    valgtOrganisasjon: Organisasjon | undefined;
}

type BreadcrumbLabel = string | ((meta: BreadCrumbMeta) => string);

interface BMIndexRouteObject extends IndexRouteObject {
    breadcrumbLabel?: BreadcrumbLabel;
}

interface BMNonIndexRouteObject extends NonIndexRouteObject {
    breadcrumbLabel?: BreadcrumbLabel;
    children?: BMRoute[];
}

type BMRouteObject = BMIndexRouteObject | BMNonIndexRouteObject;

export type BMRoute = BMRouteObject;

const CatchLastError = () => {
    const errorData = useRouteError() as IHttpError;

    return <HttpFeilSide error={errorData} />;
};

export const routeConfig: BMRoute[] = [
    {
        path: "/",
        element: (
            <Root>
                <App />
            </Root>
        ),
        errorElement: <CatchLastError />,
        children: [
            {
                path: "hjem",
                element: (
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                )
            },
            ...organisasjonRoutes,
            ...invoiceRoutes,
            ...ttpRoutes,
            ...agreementRoutes,
            ...claimsRoutes,
            ...tasksRoutes,
            ...contactsRoutes,
            ...kjopRoutes,
            {
                path: "loggut",
                element: <LoggUt />
            },
            {
                path: "ansattbrosjyre",
                element: (
                    <ProtectedRoute
                        requiredDistributor={Distributor.SB1}
                        requiredRolle={[BrukerRolle.ADMINISTRATOR, BrukerRolle.PERSONAL, BrukerRolle.RADGIVER]}
                    >
                        <Ansattbrosjyre />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Ansattbrosjyre"
            },
            {
                path: "filvisningsfeil",
                element: <FilVisningsFeil />
            },
            {
                path: "medlemmer",
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute
                                requiredDistributor={Distributor.SB1}
                                requiredRolle={[BrukerRolle.FORENING, BrukerRolle.RADGIVER]}
                            >
                                <Medlemmer />
                            </ProtectedRoute>
                        ),
                        breadcrumbLabel: "Medlemmer"
                    },
                    {
                        path: "meld-inn",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ProtectedRoute
                                        requiredRolle={[
                                            BrukerRolle.PERSONAL,
                                            BrukerRolle.FORENING,
                                            BrukerRolle.RADGIVER
                                        ]}
                                    >
                                        <MedlemInnmelding />
                                    </ProtectedRoute>
                                ),
                                breadcrumbLabel: "Meld inn medlem"
                            },
                            {
                                path: ":indeks",
                                element: (
                                    <ProtectedRoute
                                        requiredRolle={[
                                            BrukerRolle.PERSONAL,
                                            BrukerRolle.FORENING,
                                            BrukerRolle.RADGIVER
                                        ]}
                                    >
                                        <MedlemInnmelding />
                                    </ProtectedRoute>
                                )
                            }
                        ]
                    },
                    {
                        path: "meld-ut",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ProtectedRoute
                                        requiredRolle={[
                                            BrukerRolle.PERSONAL,
                                            BrukerRolle.FORENING,
                                            BrukerRolle.RADGIVER
                                        ]}
                                    >
                                        <MedlemUtmelding />
                                    </ProtectedRoute>
                                ),
                                breadcrumbLabel: "Meld ut medlem"
                            },
                            {
                                path: ":indeks",
                                element: (
                                    <ProtectedRoute
                                        requiredRolle={[
                                            BrukerRolle.PERSONAL,
                                            BrukerRolle.FORENING,
                                            BrukerRolle.RADGIVER
                                        ]}
                                    >
                                        <MedlemUtmelding />
                                    </ProtectedRoute>
                                )
                            }
                        ]
                    }
                ],
                breadcrumbLabel: "Medlemmer"
            },
            antallslisteRoutes,
            {
                path: "ansatte-navn",
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute
                                requiredRolle={[BrukerRolle.PERSONAL, BrukerRolle.FORENING, BrukerRolle.RADGIVER]}
                            >
                                <NavnelisteOversiktSide />
                            </ProtectedRoute>
                        ),
                        breadcrumbLabel: "Meld inn/ut ansatte"
                    },
                    {
                        path: "meld-inn",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ProtectedRoute
                                        requiredRolle={[
                                            BrukerRolle.PERSONAL,
                                            BrukerRolle.FORENING,
                                            BrukerRolle.RADGIVER
                                        ]}
                                    >
                                        <AnsattInnmelding />
                                    </ProtectedRoute>
                                ),
                                breadcrumbLabel: "Meld inn ansatt"
                            },
                            {
                                path: ":indeks",
                                element: (
                                    <ProtectedRoute
                                        requiredRolle={[
                                            BrukerRolle.PERSONAL,
                                            BrukerRolle.FORENING,
                                            BrukerRolle.RADGIVER
                                        ]}
                                    >
                                        <AnsattInnmelding />
                                    </ProtectedRoute>
                                )
                            }
                        ]
                    },
                    {
                        path: "meld-ut",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ProtectedRoute
                                        requiredRolle={[
                                            BrukerRolle.PERSONAL,
                                            BrukerRolle.FORENING,
                                            BrukerRolle.RADGIVER
                                        ]}
                                    >
                                        <AnsattUtmelding />
                                    </ProtectedRoute>
                                ),
                                breadcrumbLabel: "Meld ut ansatt"
                            },
                            {
                                path: ":indeks",
                                element: (
                                    <ProtectedRoute
                                        requiredRolle={[
                                            BrukerRolle.PERSONAL,
                                            BrukerRolle.FORENING,
                                            BrukerRolle.RADGIVER
                                        ]}
                                    >
                                        <AnsattUtmelding />
                                    </ProtectedRoute>
                                )
                            }
                        ]
                    },
                    {
                        path: "endre-sykelonn",
                        children: [
                            {
                                index: true,
                                breadcrumbLabel: "Endre lønn på Sykelønn",
                                element: (
                                    <ProtectedRoute requiredRolle={[BrukerRolle.PERSONAL, BrukerRolle.RADGIVER]}>
                                        <AnsattEndreSykelonn />
                                    </ProtectedRoute>
                                )
                            },
                            {
                                path: ":indeks",
                                element: (
                                    <ProtectedRoute requiredRolle={[BrukerRolle.PERSONAL, BrukerRolle.RADGIVER]}>
                                        <AnsattEndreSykelonn />
                                    </ProtectedRoute>
                                )
                            }
                        ]
                    }
                ]
            },
            {
                path: "reelle-rettighetshavere",
                element: <ReelleRettighetshavere />,
                breadcrumbLabel: "Reelle rettighetshavere"
            },
            {
                path: "dokumenter",
                element: <DokumentOversikt />,
                breadcrumbLabel: "Dokumenter"
            },

            {
                path: "tilganger",
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute
                                requiredRolle={[
                                    BrukerRolle.ADMINISTRATOR,
                                    BrukerRolle.ADMINISTRATOR_DNB,
                                    BrukerRolle.RADGIVER,
                                    BrukerRolle.RADGIVER_DNB
                                ]}
                            >
                                <TilgangerOversikt />
                            </ProtectedRoute>
                        ),
                        breadcrumbLabel: ({ valgtOrganisasjon }) =>
                            valgtOrganisasjon ? `Tilganger – ${valgtOrganisasjon.navn}` : "Tilganger"
                    },
                    {
                        path: "gi-tilgang",
                        element: (
                            <ProtectedRoute
                                requiredRolle={[
                                    BrukerRolle.ADMINISTRATOR,
                                    BrukerRolle.ADMINISTRATOR_DNB,
                                    BrukerRolle.RADGIVER,
                                    BrukerRolle.RADGIVER_DNB
                                ]}
                            >
                                <TilgangerLeggTil />
                            </ProtectedRoute>
                        ),
                        children: [
                            {
                                path: "person",
                                element: <LeggTilPerson />,
                                breadcrumbLabel: "Gi tilgang til person"
                            },
                            {
                                path: "bedrift",
                                element: <LeggTilBedrift />,
                                breadcrumbLabel: "Gi tilgang til annen bedrift"
                            }
                        ]
                    }
                ]
            },
            {
                path: "signering/gruppeliv",
                element: (
                    <ProtectedRoute
                        requiredRolle={[BrukerRolle.ADMINISTRATOR, BrukerRolle.RADGIVER]}
                        requiredDistributor={Distributor.SB1}
                    >
                        <SigneringGruppeliv />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Signer tilbud"
            },
            {
                path: "samtykke",
                element: (
                    <ProtectedRoute requiredRolle={[BrukerRolle.ADMINISTRATOR, BrukerRolle.RADGIVER]}>
                        <Samtykke />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Samtykke"
            },
            {
                path: "kontakt-oss",
                element: (
                    <ProtectedRoute>
                        {" "}
                        <Kontakt />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Kontakt oss"
            },

            {
                path: "*",
                element: <IkkeFunnetFeil />
            }
        ]
    },
    {
        path: "/feil",
        element: <Feil />
    }
];

const createBreadcrumbRoutes = (routes: BMRoute[], meta: BreadCrumbMeta) =>
    routes.map((route) => {
        const breadcrumbRoute: BMRoute = { ...route };
        if (Array.isArray(route.children)) {
            // index routes skaper duplikater, så disse hopper vi over
            breadcrumbRoute.children = createBreadcrumbRoutes(
                route.children.filter((r) => {
                    if (r.index === true && r.path !== "*") {
                        return false;
                    }

                    return true;
                }),
                meta
            );

            // hent label fra index routen ned til rot-route
            const indexRoute = route.children.find((r) => r.index);

            if (indexRoute) {
                breadcrumbRoute.breadcrumbLabel = indexRoute.breadcrumbLabel;
            }
        }

        // skriv breadcrumb til elementet som skal rendres
        if (typeof breadcrumbRoute.breadcrumbLabel === "function") {
            breadcrumbRoute.element = breadcrumbRoute.breadcrumbLabel(meta);
        } else {
            breadcrumbRoute.element = breadcrumbRoute.breadcrumbLabel ?? undefined;
        }

        return breadcrumbRoute;
    });

export const breadcrumbRoutes = (meta: BreadCrumbMeta): BMRouteObject[] => {
    return createBreadcrumbRoutes(routeConfig, meta) as BMRouteObject[];
};
