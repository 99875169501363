import { useFormContext } from "react-hook-form";

import { Select } from "@fremtind/jkl-select-react";

import { MileagePerYearInputs } from "../../MileagePerYear";

export const MileagePerYearInput: React.FC<{
    selectItems: {
        value: string;
        label: string;
    }[];
}> = ({ selectItems }) => {
    const { register } = useFormContext<MileagePerYearInputs>();

    return (
        <Select
            labelProps={{
                variant: "medium"
            }}
            items={selectItems}
            {...register("mileagePerYear", {
                required: "Du må velge en verdi"
            })}
            label="Årlig kjørelengde"
        />
    );
};
