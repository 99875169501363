import { Trackingkey, track } from "src/tracking";

import { Typography } from "@components/Typography";
import { useBruker } from "@features/bruker/queries";
import { NavCard } from "@fremtind/jkl-card-react";

import "./HomePageCTA.scss";

type HomePageCTAProps = {
    flowPath: string | undefined;
    title?: string;
    asListItem?: boolean;
};

export const HomePageCTA: React.FC<HomePageCTAProps> = ({ flowPath, title, asListItem }) => {
    const user = useBruker().bruker;
    if (!flowPath) return null;

    track({
        hendelse: Trackingkey.SeTilbud,
        produkt: "V05",
        organisasjon: user?.gjeldendeOrganisasjonsnummer ?? ""
    });

    const NavCardToFeed = (
        <NavCard
            className="cta-banner  car-insurance"
            title={title ? title : "Kjøp bilforsikring"}
            image={{
                src: "/bedrift/static/images/kjop_bil.jpg",
                alt: ""
            }}
            href="kjop/bil"
            onClick={() => {
                track({
                    hendelse: Trackingkey.LenkeTrykk,
                    lenkeId: "forside kjøp bil"
                });
            }}
        >
            <Typography>Trenger dere ny bilforsikring eller ønsker å flytte forsikring fra annet selskap?</Typography>
        </NavCard>
    );

    return asListItem ? <li>{NavCardToFeed}</li> : <>{NavCardToFeed}</>;
};
