import { ReactNode } from "react";

import { Typography } from "@components/Typography";
import { SkeletonAnimation, SkeletonElement } from "@fremtind/jkl-loader-react";

import { BreadCrumbs } from "../BreadCrumbs";
import "./SideHeader.scss";

interface SideHeaderNyProps {
    title: string;
    lead?: string | ReactNode;
    actions?: ReactNode;
    links?: ReactNode;
    loading?: boolean;
    hideBreadcrumbs?: boolean;
}

/**
 * Bruk som header på sider. Underelementer vil dukke opp til høyre for teksten.
 */
export const SideHeader = ({ title, lead, actions, links, loading, hideBreadcrumbs }: SideHeaderNyProps) => {
    return (
        <header className="page-header">
            {loading ? (
                <SkeletonAnimation>
                    <SkeletonElement className="mb-40" width={80} height={16} />
                    <SkeletonElement className="mb-24" width={400} height={100} />
                    <SkeletonElement className="mb-12" width={512} height={20} />
                    <SkeletonElement className="mb-12" width={384} height={20} />
                </SkeletonAnimation>
            ) : (
                <>
                    {!hideBreadcrumbs && <BreadCrumbs />}
                    <hgroup>
                        <Typography variant="title" component="h1">
                            {title}
                        </Typography>
                        {lead && <Typography>{lead}</Typography>}
                    </hgroup>
                    {actions && <div className="actions">{actions}</div>}
                    {links && (
                        <div className="links">
                            <Typography variant="heading-5" component="h2">
                                Lenker
                            </Typography>
                            {links}
                        </div>
                    )}
                </>
            )}
        </header>
    );
};
