import { useEffect } from "react";
import { Trackingkey, track } from "src/tracking";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { GrunndataDocumentsSection, GrunndataGoldenRecordsSection } from "../components";
import { useGetGrunndataContactInfo } from "../grunndataContactInfo.queries";
import "./GrunndataContactInfoView.scss";


export const GrunndataContactInfoView = () => {
    const grunndata = useGetGrunndataContactInfo();

    const grunndataDocsEmail = grunndata.data?.documentEmail;
    const grunndataGoldenRecords = grunndata.data?.goldenRecords;

    useEffect(() => {
        track({
            hendelse: Trackingkey.SeListe,
            type: "Golden Records (organisasjoner)",
            antall: grunndataGoldenRecords?.length ?? 0
        });
    }, [grunndataGoldenRecords]);

    return (
        <Side>
            <SideHeader
                title="Registrerte kontaktpunkter"
                lead="Her ser du kontaktpunktene som står oppført for din bedrift."
            />
            <SideInnhold loadingText="Laster inn kontaktpersonene dine" className="grunndata-contact-view">
                <GrunndataDocumentsSection email={grunndataDocsEmail} />
                <GrunndataGoldenRecordsSection
                    goldenRecords={grunndataGoldenRecords}
                    error={grunndata.data?.klientFeilmeldinger}
                />
            </SideInnhold>
        </Side>
    );
};
