import { SideInnhold } from "@components/Side";
import { useTasks } from "@features/tasks/tasks.queries";
import type { AgreementDetails as IAgreementDetails } from "@model/gen";

import { AgreementRenewal, AgreementTasksSection } from "../../components";
import { SmartDelayMessage, TravelCardSection } from "../../components/AgreementSpecificComponents";
import { AgreementClaimsSection } from "../components/AgreementClaimsSection/AgreementClaimsSection";
import { AgreementCoveragesSection } from "../components/AgreementCoverages/AgreementCoveragesSection";
import { AgreementDetailSection } from "../components/AgreementDetailSection/AgreementDetailSection";
import { AgreementDetailsButtons } from "../components/AgreementDetailsButtons/AgreementDetailsButtons";
import { AgreementDetailsHeader } from "../components/AgreementDetailsHeader";
import { AgreementDocumentsList } from "../components/AgreementDocumentsList/AgreementDocumentsList";
import { AgreementOnHold } from "../components/AgreementRenewal/AgreementOnHold";
import { CoverageComparisonTable } from "../components/AgreementSpecific/CoverageComparisonTable/CoverageComparisonTable";
import "./AgreementDetailsPreview.scss";

interface Props {
    agreementDetails: IAgreementDetails;
}

export const NewAgreementDetailsPreview = ({ agreementDetails }: Props) => {
    const tasks = useTasks();
    const filteredTasks = tasks.data?.filter((task) => task.agreementNumber === agreementDetails.agreementId);

    return (
        <>
            <AgreementDetailsHeader agreementDetails={agreementDetails} />
            <SideInnhold className="agreement-details__content">
                <AgreementOnHold agreementDetails={agreementDetails} />
                <AgreementRenewal agreementDetails={agreementDetails} />
                <AgreementDetailsButtons agreementDetails={agreementDetails} />
                <AgreementClaimsSection agreementDetails={agreementDetails} />
                <SmartDelayMessage agreementDetails={agreementDetails} />
                <AgreementDocumentsList showAsNavCard agreementDetails={agreementDetails} />
                <div className="agreement-details__grid">
                    <AgreementCoveragesSection agreement={agreementDetails} />
                    <AgreementDetailSection agreementDetails={agreementDetails} />
                </div>
                {filteredTasks && filteredTasks.length > 0 && <AgreementTasksSection agreementTasks={filteredTasks} />}
                <TravelCardSection agreementDetails={agreementDetails} />
                <CoverageComparisonTable agreementDetails={agreementDetails} />
            </SideInnhold>
        </>
    );
};
