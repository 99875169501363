import { Loader } from "@fremtind/jkl-loader-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { isValidName } from "@fremtind/jkl-validators-util";

import { useFolkeregisterSjekk } from "../../common/hooks/useFolkeregisterSjekk";
import { KundeFeilmelding } from "../../model/feilkoder.object";
import { FolkeregisterPersonVerifikasjon } from "../../model/folkeregister.typer";
import { Trackingkey } from "../../tracking";
import { PrimaryButton, SecondaryButton } from "../Button";
import { SkjemaFooter } from "../SkjemaFooter";
import { TextInput } from "../TextInput";
import "./FolkeregisterSjekk.scss";

interface FormValues {
    fornavn: string;
    etternavn: string;
    fodselsnummer: string;
}

interface Props {
    header: string;
    defaultValues?: FormValues;
    validerPerson?: (person: FormValues) => KundeFeilmelding | undefined;
    onBekreftet: (verifikasjonRespons: FolkeregisterPersonVerifikasjon, person: FormValues) => void;
    onAvbryt?: () => void;
    pendingAvhengigheter?: string;
    fremButtonText: string;
    avbrytButtonText?: string;
    classNames?: string;
}

export const FolkeregisterSjekk = ({
    header,
    defaultValues,
    validerPerson,
    onBekreftet: onSuccess,
    onAvbryt,
    fremButtonText,
    avbrytButtonText = "Avbryt",
    pendingAvhengigheter,
    classNames = ""
}: Props) => {
    const { form, error, onSubmit, mutation } = useFolkeregisterSjekk({ defaultValues, validerPerson, onSuccess });

    if (pendingAvhengigheter) {
        return <Loader textDescription={pendingAvhengigheter} />;
    }

    return (
        <div className={`folkeregister-sjekk ${classNames}`}>
            <h2 className="folkeregister-sjekk__header">{header}</h2>
            <p className="folkeregister-sjekk__ingress">
                Vi sjekker navn og fødselsnummer mot folkeregisteret for å forsikre oss om at alt er riktig.
            </p>
            <form onSubmit={form.handleSubmit(onSubmit)} className="folkeregister-sjekk__form">
                <TextInput
                    className="folkeregister-sjekk__input folkeregister-sjekk__input--wide"
                    label="Fornavn"
                    labelProps={{ variant: "medium" }}
                    dataTestautoid="fnr-input-fornavn"
                    {...form.register("fornavn", {
                        required: "Du må fylle inn et gyldig fornavn",
                        validate: (value) => isValidName(value) || "Du må fylle inn et gyldig fornavn"
                    })}
                    errorLabel={form.formState.errors.fornavn?.message}
                />
                <TextInput
                    className="folkeregister-sjekk__input folkeregister-sjekk__input--wide"
                    label="Etternavn"
                    labelProps={{ variant: "medium" }}
                    dataTestautoid="fnr-input-etternavn"
                    {...form.register("etternavn", {
                        required: "Du må fylle inn et gyldig etternavn",
                        validate: (value) => isValidName(value) || "Du må fylle inn et gyldig etternavn"
                    })}
                    errorLabel={form.formState.errors.etternavn?.message}
                />

                <TextInput
                    className="folkeregister-sjekk__input"
                    label="Fødselsnummer"
                    placeholder="11 siffer"
                    labelProps={{ variant: "medium" }}
                    dataTestautoid="fnr-input-fodselsnummer"
                    {...form.register("fodselsnummer", {
                        required: "Du må fylle inn et fødselsnummer",
                        minLength: {
                            value: 11,
                            message: "Fødselsnummeret må bestå av 11 siffer"
                        },
                        maxLength: {
                            value: 11,
                            message: "Fødselsnummeret må bestå av 11 siffer"
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: "Fødselsnummeret kan kun inneholde tall"
                        }
                    })}
                    errorLabel={form.formState.errors.fodselsnummer?.message}
                />

                {error && <ErrorMessageBox title={error.title}>{error.message}</ErrorMessageBox>}

                <SkjemaFooter>
                    <PrimaryButton
                        dataTestautoid="folkeregistersjekk-neste"
                        data-testid="folkeregistersjekk-neste"
                        track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "folkeregister-neste" }}
                        loader={{ showLoader: mutation.isPending, textDescription: "Verifiserer person" }}
                    >
                        {fremButtonText}
                    </PrimaryButton>

                    <SecondaryButton
                        type="button"
                        dataTestautoid="folkeregistersjekk-avbryt"
                        track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "folkeregister-avbryt" }}
                        onClick={() => {
                            if (onAvbryt) {
                                onAvbryt();
                            }
                        }}
                    >
                        {avbrytButtonText}
                    </SecondaryButton>
                </SkjemaFooter>
            </form>
        </div>
    );
};
