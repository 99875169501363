import { IHttpError } from "src/model/http.typer";

export interface Product {
    flowPath: string;
    productCode: string;
}

export interface AvailableProduct {
    products: Array<Product>;
    error?: IHttpError;
}
export interface GetKjopApi {
    products?: Array<Product>;
    error?: IHttpError;
    flowPath?: string;
    prodictPath?: string;
}

export async function fetchAllProducts(): Promise<any> {
    try {
        const response = await fetch("/bedrift/api/kjop/customer/productsuggestions/flow");
        const data = await response.json();
        if (response.status >= 400 && response.status < 600) {
            const error = "Bad response from server";
            return {
                error
            };
        } else {
            return {
                products: data,
                error: false,
                data
            };
        }
    } catch (error) {
        return {
            error
        };
    }
}

export async function getAvailableProducts() {
    const response = await fetchAllProducts();
    const data = response;
    const productList = data;
    return productList.products;
}

export function useAvailableProducts() {
    const query = fetchAllProducts().then(
        (response) => {
            return {
                produkter: response.data
            };
        },

        (error) => {
            return {
                error: error
            };
        }
    );
    // find reisenæring path
    return {
        produkter: query,
        //  error: query.error as IHttpError,
        query
    };
}

export function checkReisenaering() {
    getAvailableProducts().then((res: any) => {
        if (res) {
            const reisenæringPath = res.map((product: Product) => {
                if (product.productCode === "Y44") {
                    return product.flowPath.toString();
                } else {
                    return undefined;
                }
            });
            return reisenæringPath;
        } else {
            return undefined;
        }
    });
}

export function useAvailableProductsMock() {
    const query = getAvailableProductsMock().then(
        (response) => {
            return {
                data: response,
                produkter: response
            };
        },

        (error) => {
            return {
                error: error
            };
        }
    );

    return {
        produkterMock: query,
        //  error: query.error as IHttpError,
        query
    };
}

export async function getProductListMock() {
    const productList = [
        {
            flowPath: "/bedrift/api/kjop/customer/productsuggestions/flow",
            productCode: "Y44"
        }
    ];
    return productList;
}

export function getAvailableProductsMock() {
    return getProductListMock() // fetchAllProducts()
        .then(function (response) {
            return response;
        })
        .then(function (data) {
            const productList = data;
            return productList;
        });
}

export const checkReisenaeringMOCK = getAvailableProductsMock().then((res: any) => {
    if (res) {
        const reisenæringPath = res.map((product: Product) => {
            if (product.productCode === "Y44") {
                return product.flowPath;
            } else {
                return undefined;
            }
        });
        return reisenæringPath;
    }
});
