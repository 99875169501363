import { useParams } from "react-router";

import { useUpdatableFields } from "../agreementupdates.queries";
import "./AgreementUpdateFlow.scss";
import { Loader } from "./Loader";
import { Mileage } from "./Mileage";
import { MileagePerYear } from "./MileagePerYear";

export const AgreementUpdateFlow: React.FC<{
    flowType: "KILOMETERSTAND" | "KJORELENGDE";
}> = ({ flowType }) => {
    const { agreementId } = useParams();
    const { data: updatableFieldsResponse, isLoading } = useUpdatableFields(agreementId!); //Vi vet at denne finnes, da komponenten kun eksisterer på /:id/administrer

    const updatableFields = updatableFieldsResponse?.updatableFields || [];

    if (isLoading) return <Loader />;
    return (
        <>
            {flowType === "KILOMETERSTAND" && <Mileage updatableFields={updatableFields} />}
            {flowType === "KJORELENGDE" && <MileagePerYear updatableFields={updatableFields} />}
        </>
    );
};
