import { useContext } from "react";

import { Side, SideHeader, SideInnhold } from "src/components/Side";
import ThemeContext from "src/context/ThemeContext";

import { Typography } from "@components/Typography";
import { ProductAccordions } from "@features/kjop/components";
import {
    ProductCategory,
    ansatteSection,
    driftSection,
    kjoretoySection,
    landbrukSection
} from "@features/kjop/kjop.data";

import "./AlleForsikringer.scss";

export function AlleForsikringer() {
    const { distributor } = useContext(ThemeContext);

    let products = [ansatteSection, kjoretoySection, driftSection] as ProductCategory[];

    if (distributor === "sb1") {
        products = [...products, landbrukSection];
    }

    const nonEmptyCategories = products.filter((category) => Object.keys(category.products).length > 0);

    return (
        <Side>
            <SideHeader title="Alle forsikringer" />
            <SideInnhold className="bm-getinsurance">
                {nonEmptyCategories.map((category) => (
                    <section className="bm-getinsurance" key={category.name}>
                        <Typography component="h2" variant="heading-4" className="jkl-spacing-xs--bottom">
                            {category.name}
                        </Typography>
                        <ProductAccordions products={category.products} />
                    </section>
                ))}
            </SideInnhold>
        </Side>
    );
}
