import { useState } from "react";

import { isAfter, isBefore } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { isDefined } from "src/common/utils";

import { ExcelExportLink } from "@components/ExcelExportLink";
import { SearchField } from "@components/SearchField";
import { Typography } from "@components/Typography";
import { useAgreementList } from "@features/agreements/agreements.queries";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { KjoretoyTabell } from "@features/kjoretoy/components";
import { SecondaryButton } from "@fremtind/jkl-button-react";
import { Select } from "@fremtind/jkl-select-react";
import { AgreementOverview, Vehicle } from "@model/gen";

import styles from "./Vehicles.module.scss";
import { handleVehicleExcelExport } from "./excelExport";
import { VehicleListResponse } from "./loader";

interface Props {
    vehicleLists: VehicleListResponse;
}

interface MassagedVehicle extends Vehicle {
    avtalenummer: number;
    agreementIndex: string;
}

type AvtaleFilter = "Alle kjøretøy" | "Alle tilhengere" | string;

type StatusFilter = "Alle" | "Innmeldt" | "Utmeldt";
const defaultSearchParams = new URLSearchParams({
    avtalenummer: "Alle kjøretøy",
    status: "Alle"
});

export const VehiclesComposer = (props: Props) => {
    const [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);
    const [registrationFilter, setRegistrationFilter] = useState("");

    const agreements = useAgreementList().data?.agreements ?? [];
    const vehicleAgreementList = props.vehicleLists.map((agreement) => {
        return {
            ...agreement,
            agreementName: agreements.find((a) => a.agreementId === agreement.agreementNumber)?.productName
        };
    });

    const getFilterValue = (field: string) => searchParams.get(field);

    const setFilterValue = (field: string, value: string) => {
        // avoid mutation
        const sp = new URLSearchParams(searchParams);

        if (!value) {
            sp.delete(field);
        } else {
            sp.set(field, value);
        }

        setSearchParams(sp, { replace: true });
    };

    const clearFilter = () => {
        setRegistrationFilter("");
        setSearchParams(defaultSearchParams);
    };

    const agreementFilter: AvtaleFilter = getFilterValue("avtalenummer") || "";
    const statusFilter: StatusFilter = (getFilterValue("status") as StatusFilter) || "Alle";

    const filteredAgreements = props.vehicleLists.filter((vehicleList) => {
        const agreementMeta = agreements.find((agreement) => agreement.agreementId === vehicleList.agreementNumber);

        if (!agreementMeta) {
            return false;
        }

        return agreementMatchesFilter(agreementMeta, agreementFilter);
    });

    const massagedAgreements = filteredAgreements.map((agreement) => {
        const vehicleList = agreement.vehicles.map((vehicle) => {
            return {
                ...vehicle,
                avtalenummer: Number(agreement.agreementNumber),
                agreementIndex: agreement.agreementIndex
            };
        });
        return {
            ...agreement,
            vehicles: vehicleList
        };
    });

    const vehicles = massagedAgreements.reduce<Array<MassagedVehicle>>((acc, vehicleList) => {
        return [...acc, ...vehicleList.vehicles];
    }, []);

    const filteredVehicles = vehicles.filter((vehicle) => {
        if (statusFilter !== "Alle" && !vehicleHasStatus(vehicle, statusFilter)) {
            return false;
        }

        if (!registrationFilter) {
            return true;
        }

        return vehicleMatchesSearch(vehicle, registrationFilter);
    });

    const trailerAgreementsCount = agreements.filter((a) => a.productCode === AvtaleProduktID.FLATE_TILHENGER).length;
    const vehicleAgreementsCount = agreements.length - trailerAgreementsCount;

    const vehicleIds = [...vehicles.map((enkeltKjoretoy) => enkeltKjoretoy.sequenceNumber.toString())];

    const tilgjengeligeAvtaleTypeFilter = [
        vehicleAgreementsCount > 0 ? "Alle kjøretøy" : null,
        trailerAgreementsCount > 0 ? "Alle tilhengere" : null
    ]
        .concat(
            // @ts-ignore dette fungerer, men typescript liker at label er en komponent
            vehicleAgreementList.map((agreement) =>
                agreement.agreementNumber
                    ? {
                          label: (
                              <Typography component="span">
                                  {agreement.agreementName?.toString()}{" "}
                                  <Typography component="span">({agreement.agreementNumber.toString()})</Typography>
                              </Typography>
                          ),

                          value: agreement.agreementNumber.toString()
                      }
                    : null
            )
        )

        .filter(isDefined);

    const selectedAgreement = agreements.find((agreement) => agreement.agreementId.toString() === agreementFilter);

    return (
        <>
            <Typography variant="heading-2" className={styles.heading}>
                Hvilke kjøretøy vil du se?
            </Typography>

            <div className={styles.wrapper}
            >
                <form className="flex flex-wrap gap-32 ml-0 items-end">
                    <SearchField
                        dataTestautoid="flåte-søk"
                        match={registrationFilter}
                        setMatch={setRegistrationFilter}
                        matchedCount={filteredVehicles.length ?? 0}
                        placeholder="Kjennemerke eller merke"
                        label="Søk"
                        width="330px"
                    />

                    <Select
                        label="Avtalestatus"
                        name="status"
                        items={["Alle", "Innmeldt", "Utmeldt"]}
                        onChange={(e) => setFilterValue("status", e.target.value)}
                        value={statusFilter}
                    />

                    <Select
                        label="Avtale"
                        name="avtalenummer"
                        items={tilgjengeligeAvtaleTypeFilter}
                        onChange={(e) => setFilterValue("avtalenummer", e.target.value)}
                        value={agreementFilter}
                        width="320px"
                    />

                    <SecondaryButton type="button" onClick={clearFilter}>
                        Tøm filter
                    </SecondaryButton>
                </form>
                {!!vehicleIds.length && (
                    <ExcelExportLink
                        export="kjoretoy"
                        handler={() =>
                            handleVehicleExcelExport(
                                Array.from(new Set(filteredVehicles.map((v) => v.agreementIndex))).map((index) => ({
                                    id: index,
                                    sequenceNumbers: filteredVehicles
                                        .filter((v) => v.agreementIndex === index)
                                        .map((v) => v.sequenceNumber)
                                }))
                            )
                        }
                        className={styles.exportButton}
                    />
                )}
            </div>
            <KjoretoyTabell
                kjoretoy={filteredVehicles}
                avtale={selectedAgreement}
                isTilhenger={
                    agreementFilter === "Alle tilhengere" ||
                    selectedAgreement?.productCode === AvtaleProduktID.FLATE_TILHENGER
                }
            />
        </>
    );
};

function vehicleMatchesSearch(vehicle: Vehicle, search: string) {
    return [vehicle.registrationNumber, vehicle.brand, vehicle.model]
        .filter(isDefined)
        .some((value) => value.toLowerCase().includes(search.toLowerCase()));
}

function vehicleHasStatus(vehicle: Vehicle, status: StatusFilter) {
    switch (status) {
        case "Innmeldt": {
            if (!vehicle.enrollmentDateTo) {
                return true;
            }

            if (isAfter(new Date(vehicle.enrollmentDateTo), new Date())) {
                return true;
            }

            return false;
        }
        case "Utmeldt": {
            if (!vehicle.enrollmentDateTo) {
                return false;
            }

            if (isBefore(new Date(vehicle.enrollmentDateTo!), new Date())) {
                return true;
            }

            return false;
        }
        default: {
            return true;
        }
    }
}

function agreementMatchesFilter(agreement: AgreementOverview, agreementFilter: AvtaleFilter) {
    switch (agreementFilter) {
        case "Alle kjøretøy": {
            return agreement.productCode !== AvtaleProduktID.FLATE_TILHENGER;
        }
        case "Alle tilhengere": {
            return agreement.productCode === AvtaleProduktID.FLATE_TILHENGER;
        }
        default: {
            return agreement.agreementId.toString() === agreementFilter;
        }
    }
}
