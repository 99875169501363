import { useState } from "react";

import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Trackingkey, track } from "src/tracking";

import { SkjemaFlyt, Steg, StegProps } from "@components/SkjemaFlyt";
import {
    AgreementUpdateRequests,
    useRequestUpdateAgreement
} from "@features/agreement-updates/agreementupdates.queries";
import {
    MILEAGE_FIELD_NAME,
    MILEAGE_PER_YEAR_FIELD_NAME,
    SUCCESSFULL_UPDATE_CODE,
    findField
} from "@features/agreement-updates/agreementupdates.utils";
import { useAgreementDetails } from "@features/agreements/agreements.queries";
import { useBruker } from "@features/bruker/queries";
import { AgreementUpdatableField, AgreementUpdateResponse } from "@model/gen";
import { UseMutationResult } from "@tanstack/react-query";

import { PriceSummary, UpdateMileage } from "./steps";

export type AgreementUpdateStep = StegProps & {
    agreementId: string;
    updatableFields: AgreementUpdatableField[];
    initialValues: {
        mileage: string;
        mileagePerYear: string;
    };
    agreementUpdateMutation: UseMutationResult<AgreementUpdateResponse, Error, AgreementUpdateRequests, unknown>;
    onSubmit: (shouldSetNewPrice: boolean, onFrem: (programmatisk: boolean) => void) => void;
    error: string | null;
    hasSuccessFullyUpdated: boolean;
    isMileageUpdatable: boolean;
};

export type MileagePerYearInputs = {
    mileage?: string;
    mileagePerYear: string;
};

const mileagePerYearSteps: Steg<AgreementUpdateStep>[] = [
    {
        stegnavn: "oppdater-kjørelengde",
        element: (props) => <UpdateMileage {...props} />
    },
    {
        stegnavn: "oppsummering",
        element: (props) => <PriceSummary {...props} />
    }
];

export const MileagePerYear: React.FC<{
    updatableFields: AgreementUpdatableField[];
}> = ({ updatableFields }) => {
    const { agreementId } = useParams();
    const { valgtOrganisasjon } = useBruker();
    const [error, setError] = useState<string | null>(null);
    const [hasSuccessFullyUpdated, setHasSuccessFullyUpdated] = useState(false);

    const { data: agreement } = useAgreementDetails(agreementId || "");
    const productCode = agreement?.productCode;

    const mileagePerYearField = findField(updatableFields, MILEAGE_PER_YEAR_FIELD_NAME);
    const mileageField = findField(updatableFields, MILEAGE_FIELD_NAME);
    const agreementUpdateMutation = useRequestUpdateAgreement();
    const { mutate } = agreementUpdateMutation;

    const currentMileage = mileageField?.selected?.valueId || "";
    const currentMileagePerYear = mileagePerYearField?.selected?.valueId || "";

    const formMethods = useForm<MileagePerYearInputs>({
        defaultValues: {
            mileage: currentMileage,
            mileagePerYear: currentMileagePerYear
        }
    });

    const isMileagePerYearUpdatable = !!mileagePerYearField;
    const isMileageUpdatable = !!mileageField;

    if (!isMileagePerYearUpdatable) return null;

    const mileage = formMethods.watch("mileage");
    const mileagePerYear = formMethods.watch("mileagePerYear");

    const hasUpdatedMileage = mileage !== currentMileage;
    const hasUpdatedMileagePerYear = mileagePerYear !== currentMileagePerYear;

    const isMileageLowerThanInitial = parseInt(mileage || "0") < parseInt(currentMileage || "0");

    let hasUpdatedFields = hasUpdatedMileagePerYear;

    if (mileageField) {
        hasUpdatedFields = hasUpdatedFields && hasUpdatedMileage;
    }

    const onSubmit = (shouldSetNewPrice: boolean, onFrem: (programmatisk: boolean) => void) => {
        if (!hasUpdatedFields) return;

        if (isMileageLowerThanInitial) {
            formMethods.setError("mileage", {
                type: "custom",
                message: "Kilometerstand kan ikke være lavere enn dagens oppgitte kilometerstand."
            });
            return;
        }

        formMethods.clearErrors();
        setError(null);

        const updatableFields: AgreementUpdatableField[] = [];
        if (hasUpdatedMileage) {
            updatableFields.push({
                fieldId: mileageField?.fieldId,
                // @ts-ignore Resten av feltene er ikke i bruk
                selected: {
                    valueId: mileage ?? ""
                }
            });
        }
        if (hasUpdatedMileagePerYear) {
            updatableFields.push({
                fieldId: mileagePerYearField?.fieldId,
                // @ts-ignore Resten av feltene er ikke i bruk
                selected: {
                    valueId: mileagePerYear ?? ""
                }
            });
        }

        mutate(
            {
                id: agreementId ?? "",
                organisationNumber: valgtOrganisasjon?.organisasjonsnummer ?? "",
                payload: {
                    id: agreementId,
                    confirmed: shouldSetNewPrice,
                    updatableFields
                }
            },
            {
                onSuccess: (res) => {
                    const { success, errorCodes, infoCodes } = res;
                    if (!success) {
                        const errorMessage = shouldSetNewPrice
                            ? "Det har skjedd en feil under oppdatering av avtalen, og endringen ble ikke lagret. Prøv på nytt eller kontakt rådgiver"
                            : "En teknisk feil har oppstått. Beklager!";

                        if (errorCodes && errorCodes.length > 0) {
                            setError(errorMessage);
                        }
                        return;
                    }

                    if (!shouldSetNewPrice) {
                        onFrem(true);
                    }

                    if (infoCodes?.some((code) => code === SUCCESSFULL_UPDATE_CODE)) {
                        setHasSuccessFullyUpdated(true);
                        track({
                            hendelse: Trackingkey.Endre,
                            type: "endre avtale",
                            avtale: productCode,
                            tidligereVerdi: currentMileagePerYear,
                            nyVerdi: mileagePerYear,
                            felt: "kjørelengde"
                        });

                        if (hasUpdatedMileage) {
                            track({
                                hendelse: Trackingkey.Endre,
                                type: "endre avtale",
                                avtale: productCode,
                                tidligereVerdi: currentMileage,
                                nyVerdi: mileage,
                                felt: "kilometerstand"
                            });
                        }
                    }
                }
            }
        );
    };

    return (
        <section className="update-insurance__form">
            <SkjemaFlyt
                formMethods={formMethods}
                flytnavn="Oppdater kjørelengde"
                stegListe={mileagePerYearSteps}
                {...{
                    agreementId,
                    updatableFields,
                    initialValues: {
                        mileage: currentMileage || "",
                        mileagePerYear: currentMileagePerYear || ""
                    },
                    agreementUpdateMutation,
                    onSubmit,
                    error,
                    hasSuccessFullyUpdated,
                    isMileageUpdatable
                }}
            />
        </section>
    );
};
