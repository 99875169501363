import { config } from "./config";
import { Flag } from "./featureflag.model";

export const validateFeatureflag = (flag: Flag | undefined) => {
    if (flag === undefined) {
        return false;
    }

    return !!config[flag];
};
