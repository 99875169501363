import { utils } from "@features/agreements";
import { DataTable } from "@fremtind/jkl-table-react";
import { AgreementCoverageTable } from "@model/gen";

type DekningerProps = {
    coverages: AgreementCoverageTable;
};

export function CoverageTable({ coverages }: DekningerProps) {
    if (!coverages.rows.length) {
        return null;
    }

    return <DataTable collapseToList {...generateTableData(coverages)} />;
}

function generateTableData(coverages: AgreementCoverageTable) {
    const rows = coverages.rows.map(({ row }) => row.map((cell) => utils.formatValue(cell.value, cell.type)));

    return { rows: rows, columns: coverages.columnNames };
}
