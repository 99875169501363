import { useLocation } from "react-router-dom";

import { http } from "@features/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Bruker } from "../../model/bruker.typer";
import { IHttpError } from "../../model/http.typer";

export const brukerQueryKey = ["bruker"];

export async function fetchBruker() {
    const res = await http.get<Bruker>("innloggetbruker");
    return res;
}

export function useBruker() {
    const location = useLocation();

    const query = useQuery({
        queryKey: brukerQueryKey,
        queryFn: () => fetchBruker(),
        select: (bruker) => {
            return {
                bruker: bruker,
                valgtOrganisasjon: bruker.valgtOrganisasjon
            };
        },
        staleTime: Infinity,
        enabled: location.pathname !== "/feil"
    });

    return {
        bruker: query.data?.bruker,
        valgtOrganisasjon: query.data?.valgtOrganisasjon,
        error: query.error as unknown as IHttpError,
        query
    };
}

export const useIsManaged = () => {
    const { bruker } = useBruker();

    const isManaged = !!bruker?.radgiverKontaktInfo?.beskrivelse;

    return isManaged;
};


interface SetValgtOrganisasjonArgs {
    organisasjonsnummer: string;
}

export async function setValgtOrganisasjon(args: SetValgtOrganisasjonArgs) {
    const res = http.put<Bruker>(`valgtorganisasjon/${args.organisasjonsnummer}`);

    return res;
}

async function signOut() {
    const res = http.post<{ loginurl: string; "login-completeurl": string }>("loggut");

    return res;
}

export function useSignOut() {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: signOut,
        onSuccess: () => {
            queryClient.setQueryData(brukerQueryKey, { ...queryClient.getQueryData(brukerQueryKey), loggetInn: false });
        }
    });

    return mutation;
}
