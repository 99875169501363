import { Link as RRLink } from "react-router-dom";

import { Link } from "@fremtind/jkl-core";
import { formatValuta } from "@fremtind/jkl-formatters-util";
import { AgreementDetails } from "@model/gen";

interface Props {
    currentAgreement: AgreementDetails;
    newAgreement: AgreementDetails;
}

export const FornyelseAntallKanEndres = ({ currentAgreement, newAgreement }: Props) => {
    if (currentAgreement.coreSystem === "PARIS") {
        return (
            <>
                Årlig pris endres til {formatValuta(newAgreement.annualPremium)}. Hvis du ønsker å endre antall ansatte
                så kan du gjøre det{" "}
                <RRLink to={`/ansatte-antall/endre-antall/${currentAgreement.id}`} className="jkl-link">
                    her
                </RRLink>
                . Vær oppmerksom på at dette kan føre til ny pris og faktura.
            </>
        );
    }
    return (
        <>
            Årlig pris endres til {formatValuta(newAgreement.annualPremium)}. Se{" "}
            <Link
                href={`/bedrift/api/dokumenter/avtale/pdf/${currentAgreement.id}/fornyelse`}
                target="_blank"
                rel="noopener noreferrer"
            >
                nytt avtaledokument
            </Link>{" "}
            for detaljer. Hvis du ønsker å endre antall ansatte så kan du gjøre det{" "}
            <RRLink to={`/ansatte-antall/endre-antall/${currentAgreement.id}`} className="jkl-link">
                her
            </RRLink>
            . Vær oppmerksom på at dette kan føre til ny pris og faktura.
        </>
    );
};
