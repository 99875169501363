import { Suspense } from "react";

import { Await, useLoaderData } from "react-router-dom";

import { Loader } from "@fremtind/jkl-loader-react";
import type { ClaimOverview } from "@model/gen";
import { ErrorBoundary } from "@sentry/react";

import { ClaimsDetails } from "./ClaimsDetails";

export const ClaimsDetailsComposer = () => {
    const agreemenClaimsFetcher = useLoaderData() as ClaimOverview;

    return (
        <Suspense fallback={<Loader textDescription="Henter skadesaker" />}>
            <Await resolve={agreemenClaimsFetcher} errorElement={<ErrorBoundary />}>
                {(data: ClaimOverview) => <ClaimsDetails claimsDetails={data} />}
            </Await>
        </Suspense>
    );
};
