import { SkeletonAnimation, SkeletonButton, SkeletonElement, SkeletonInput } from "@fremtind/jkl-loader-react";

export const Loader = () => (
    <SkeletonAnimation className="update-insurance__form">
        <div className="jkl-spacing-xs--bottom">
            <SkeletonElement width={300} height={40} />
        </div>
        <div className="jkl-spacing-xl--bottom">
            <SkeletonElement width={350} height={100} />
        </div>
        <div className="jkl-spacing-l--bottom">
            <SkeletonInput />
        </div>
        <div className="jkl-spacing-l--bottom">
            <SkeletonInput />
        </div>
        <div className="flex gap-24">
            <SkeletonButton width={100} />
            <SkeletonButton width={150} />
        </div>
    </SkeletonAnimation>
);
