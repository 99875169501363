import { Link } from "react-router-dom";

export const PrefornyelseNavnKanEndres = () => {
    return (
        <>
            Ny pris og avtaledokument er synlig 35 dager før fornyelsesdato. Hvis du ønsker å melde inn eller ut ansatte
            så kan du gjøre det{" "}
            <Link to="/ansatte-navn" className="jkl-link">
                her
            </Link>
            . Vær oppmerksom på at dette kan føre til ny pris og faktura.
        </>
    );
};
