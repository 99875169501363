import { AvtaleTilEndring } from "./EndreAntall";

export function onlyEdited(acc: Array<AvtaleTilEndring>, cur: AvtaleTilEndring) {
    const filtered = { ...cur, grupper: cur.grupper.filter((gruppe) => gruppe.newNumber) };
    if (filtered.grupper.length > 0) {
        return [...acc, filtered];
    }

    return acc;
}
