export const GetFormData = (agreementNr: string) => {
    const mileageSelectionsCar = [
        "8000",
        "12000",
        "16000",
        "20000",
        "25000",
        "30000",
        "40000",
        "50000",
        "60000",
        "75000",
        "Ubegrenset"
    ];

    const mileageSelectionTruck = [
        "8000",
        "15000",
        "20000",
        "25000",
        "30000",
        "40000",
        "50000",
        "65000",
        "80000",
        "100000",
        "120000",
        "140000",
        "160000",
        "Ubegrenset"
    ];

    const vehicleType = agreementNr === "V05" ? "car" : agreementNr === "V20" ? "truck" : "";

    if (vehicleType === "car") {
        return mileageSelectionsCar;
    } else if (vehicleType === "truck") {
        return mileageSelectionTruck;
    } else {
        return undefined;
    }
};
