import { AnimatePresence, motion } from "framer-motion";
import { brukerHarTilgang, isRadgiver } from "src/common/rolle";
import { SecondaryLinkButton } from "src/components/Button";
import { Side, SideHeader, SideInnhold } from "src/components/Side";
import { BmSkeletonTable } from "src/components/Skeleton";
import { Typography } from "src/components/Typography";
import { useBruker } from "src/features/bruker/queries";
import { BrukerRolle } from "src/model/bruker.typer";
import { Trackingkey, track } from "src/tracking";

import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { useAnsattDetaljer, useAnsattList } from "@features/navneliste/queries/ansatt.queries";
import { SkeletonAnimation, SkeletonInput } from "@fremtind/jkl-loader-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { InsuredTable } from "../Oversikt";
import "./NavnelisteOversiktSide.scss";

export function NavnelisteOversiktSide() {
    const { bruker } = useBruker();
    const query = useAnsattList();

    const harSykelonn = query.data?.entity?.headere.some((h) => h.produktkode === AvtaleProduktID.SYKELONN) ?? false;

    const hasFetchError = query.data?.feilmelding;
    const hasErrorMessage = hasFetchError && Object.keys(hasFetchError).length > 0 ? true : false;

    track({
        hendelse: Trackingkey.SeListe,
        type: "ansattliste navn",
        antall: query.data?.entity?.ansatte?.length ?? 0
    });

    return (
        <Side>
            <SideHeader
                title="Meld inn/ut ansatte"
                lead="Her ser du en oversikt over ansatte på navnebaserte forsikringer."
            />
            <SideInnhold query={query} loadingText="Henter ansatte">
                <div className="ansatte-oversikt__actions">
                    {!hasErrorMessage && (
                        <SecondaryLinkButton
                            className="ansatte-oversikt__meld-inn-knapp"
                            to="meld-inn"
                            onClick={() => {
                                track([
                                    {
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "ansatte-oversikt-meld-inn-knapp"
                                    },
                                    {
                                        hendelse: Trackingkey.Skjemaflyt,
                                        handling: "start",
                                        flytnavn: "meld-inn-ansatt",
                                        stegnavn: "info"
                                    }
                                ]);
                            }}
                            data-testautoid="ansatte-meld-inn-knapp"
                        >
                            Meld inn ansatt
                        </SecondaryLinkButton>
                    )}
                    {(query.data?.entity?.ansatte ?? []).length > 0 && (
                        <SecondaryLinkButton
                            className="ansatte-oversikt__meld-inn-knapp"
                            to="meld-ut"
                            onClick={() => {
                                track([
                                    {
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "ansatte-oversikt-meld-ut-knapp"
                                    },
                                    {
                                        hendelse: Trackingkey.Skjemaflyt,
                                        handling: "start",
                                        flytnavn: "meld-ut-ansatt",
                                        stegnavn: "info"
                                    }
                                ]);
                            }}
                            data-testautoid="ansatte-meld-ut-knapp"
                        >
                            Meld ut ansatt
                        </SecondaryLinkButton>
                    )}
                    {harSykelonn && (brukerHarTilgang(bruker!, BrukerRolle.PERSONAL) || isRadgiver(bruker)) && (
                        <SecondaryLinkButton
                            className="ansatte-oversikt__meld-inn-knapp"
                            to="endre-sykelonn"
                            lenkeId="endre-sykelønn-oversikt"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "ansatte-oversikt-endre-sykelønn"
                                });
                            }}
                            data-testautoid="ansatte-endre-sykelonn-knapp"
                        >
                            Endre lønn på Sykelønn
                        </SecondaryLinkButton>
                    )}
                </div>
                {!query.isFetching ? (
                    <>
                        {query.isError && (
                            <ErrorMessageBox title="Feil ved henting av ansatte">
                                Vennligst prøv igjen senere eller kontakt rådgiver
                            </ErrorMessageBox>
                        )}
                        <AnimatePresence initial={false}>
                            <motion.div
                                key="ansatte-liste"
                                initial={{ opacity: 0 }}
                                animate={{ transition: { delay: 0.3 }, opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {query.data && (
                                    <>
                                        <InsuredTable
                                            headers={query.data.entity?.headere}
                                            rows={query.data.entity?.ansatte}
                                            useDetails={useAnsattDetaljer}
                                            meldUtButtonProps={{
                                                to: (ansatt) => `meld-ut/${ansatt.indeks}`,
                                                tracking: () => {
                                                    track({
                                                        hendelse: Trackingkey.Knappetrykk,
                                                        knappeId: "ansatte-meld-ut-knapp"
                                                    });
                                                }
                                            }}
                                            meldInnButtonProps={{
                                                to: (ansatt) => `meld-inn/${ansatt.indeks}`,
                                                tracking: () => {
                                                    track({
                                                        hendelse: Trackingkey.Knappetrykk,
                                                        knappeId: "ansatte-meld-inn-knapp"
                                                    });
                                                }
                                            }}
                                            exportLink="ansattliste"
                                        />
                                        {!hasErrorMessage && (
                                            <Typography
                                                variant="heading-2"
                                                className="jkl-spacing-4xl--top text-center"
                                                hidden={!!query.data.entity?.ansatte.length}
                                            >
                                                Ingen ansatte er meldt inn enda...
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </motion.div>
                        </AnimatePresence>
                    </>
                ) : (
                    <AnimatePresence>
                        <motion.div
                            key="ansatte-liste-skeleton"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <SkeletonAnimation>
                                <div className="ansatte-oversikt__skeleton-filters">
                                    <SkeletonInput />
                                    <SkeletonInput />
                                </div>

                                <BmSkeletonTable
                                    contentHeight={80}
                                    colsHalfWidth
                                    headingWidths={[80, 170]}
                                    columns={2}
                                    rows={4}
                                />
                            </SkeletonAnimation>
                        </motion.div>
                    </AnimatePresence>
                )}
            </SideInnhold>
        </Side>
    );
}
