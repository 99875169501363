import { AgreementOptionField, AgreementUpdatableField } from "@model/gen";

export const MILEAGE_PER_YEAR_FIELD_NAME = "kjorelengde";
export const MILEAGE_FIELD_NAME = "kmStand";
export const MILEAGE_LAST_CHECKED_FIELD_NAME = "kmStandAvlest";

export const SUCCESSFULL_UPDATE_CODE = "INFO_AGREEMENT_UPDATED_SUCCESSFULLY";

type AvailableOptions = Array<AgreementOptionField>;

export const updatesResponseToSelectItems = (options: AvailableOptions) => {
    const optionsAsSelectItems = options.map((option) => ({
        label: option.value,
        value: option.valueId
    }));

    const sorted = optionsAsSelectItems.sort((a, b) => Number(a.value) - Number(b.value));
    return sorted;
};

export const findField = (options: AgreementUpdatableField[], fieldName: string) => {
    return options.find((field) => field.semanticId === fieldName);
};

export const deriveMileageFromLabel = (label: string) => {
    const withoutSpaces = label.replace(/\s/g, "");
    const match = withoutSpaces.match(/(\d+)/);
    return match ? match[0] : "";
};
