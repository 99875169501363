import { LoaderFunction } from "react-router-dom";
import { queryClient } from "src/common/queryClient";

import type { GrunndataContactInfoDto } from "@model/gen";

import { getGrunndataContactInfo, grunndataContactInfoQueryKeys } from "./grunndataContactInfo.queries";

export const grunndataContactInfoLoader: LoaderFunction = async () => {
    const grunndata = await queryClient.ensureQueryData<GrunndataContactInfoDto>({
        queryKey: grunndataContactInfoQueryKeys.all,
        queryFn: getGrunndataContactInfo
    });

    if (grunndata) {
        const dataFetcher = grunndata;

        return { dataFetcher };
    }

    return Promise.resolve();
};
