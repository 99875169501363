import {
    AvtaleGruppeDto,
    AvtaleOppdateringStatusDto,
    EmployeesNameListOverview,
    EndreLonnSykelonnRequest,
    FbmResponseEntityAnsattEndringDto,
    FbmResponseEntityMedlemEndringDto,
    MembersNameListOverview,
    NavnelisteAvtaleDetaljerDto,
    NavnelisteDetaljerDto,
    NavnelistePersonDto,
    Oppdatering,
    OppdateringAvtaleResultatDto,
    OppdateringResultatDto,
    OppdateringStatusDto
} from "@model/gen";

export type Person = NavnelistePersonDto;

export type Detaljer = NavnelisteDetaljerDto;

export type AvtaleDetaljer = NavnelisteAvtaleDetaljerDto;

export type OppdateringStatus = OppdateringStatusDto;

export type OppdateringStatusAvtale = AvtaleOppdateringStatusDto;

export type OppdateringStatusAvtaleGruppe = AvtaleGruppeDto;

export type OppdateringRequest = Oppdatering;

export type OppdateringResponse = OppdateringResultatDto;

export type OppdateringResponseAvtale = OppdateringAvtaleResultatDto;

export enum UtmeldingStatusKode {
    UTMELDING_MULIG = 1,
    APEN_SKADESAK = 2,
    PERSON_UNDER_INNMELDING = 3,
    PERSON_UNDER_UTMELDING = 4
}

export enum EndringStatusKode {
    ENDRING_MULIG = 1,
    PRODUKT_STOTTER_IKKE_ENDRING = 2,
    PERSON_UNDER_INNMELDING = 3,
    PERSON_UNDER_UTMELDING = 4
}

export enum InnmeldingStatusKode {
    INNMELDING_MULIG = 1,
    ALLEREDE_INNMELDT = 2
}

export enum OppdateringResponseAvtaleStatus {
    OPPDATERING_GJENNOMFORT = "OPPDATERING_GJENNOMFORT",
    UNDER_OPPDATERING = "UNDER_OPPDATERING",
    OPPDATERING_FEILET = "OPPDATERING_FEILET"
}

export type ListHeaderCell = {
    avtaleNavn: string;
    produktkode: string;
};

export type MinMaxDate = {
    min: Date;
    max: Date;
};

export enum Feilmelding {
    VELG_FORSIKRING_INGEN_FORSIKRING = "Du må velge minst én forsikring.",
    VELG_FORSIKRING_INGEN_GRUPPE = "Du må velge en gruppe."
}

export type MedlemOppdateringResponse = Required<FbmResponseEntityMedlemEndringDto>;

export type AnsattOppdateringResponse = Required<FbmResponseEntityAnsattEndringDto>;

export type AnsattNavnelisteResponse = EmployeesNameListOverview;

export type MedlemListResponse = MembersNameListOverview;

export type EndreSykelonnRequest = EndreLonnSykelonnRequest[];
