// navnet på enumen representerer env variablen som kan brukes til å slå på funksjonalitet
// verdien på enumen representerer forventet entry fil
export enum Distributor {
    DNB = "dnb",
    SB1 = "sb1",
    MEG = "meg" // meglet
}

export interface Theme {
    distributor: Distributor;
    BrandHeader: () => JSX.Element | null;
}
