import React from "react";

import { AnimatePresence } from "framer-motion";
import { Await, LoaderFunction, defer, useLoaderData } from "react-router-dom";
import { queryClient } from "src/common/queryClient";
import { Bruker } from "src/model/bruker.typer";

import { Side, SideHeader, SideInnhold } from "@components/Side";
import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import { NumberBasedAgreementDto } from "@model/gen";

import { queryKey, requestAntallsbaserteAvtaler } from "../../antallsliste.queries";
import { LoaderCard } from "../../components";
import { AntallslisteComposer } from "./AntallslisteComposer";
import "./AntallslisteOversikt.scss";

export const loader: LoaderFunction = async () => {
    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });

    if (bruker) {
        return defer({
            avtaler: queryClient.fetchQuery({
                queryKey: [
                    queryKey.list(bruker.gjeldendeOrganisasjonsnummer! as string),
                    () => requestAntallsbaserteAvtaler(),
                    {
                        staleTime: 10000
                    }
                ],
                queryFn: () => requestAntallsbaserteAvtaler()
            })
        });
    }

    return Promise.resolve();
};

const LoaderCards = () => {
    return (
        <div className="antallsliste">
            <ul className="antallsliste">
                {new Array(6).fill(undefined).map((_, i) => (
                    <li key={i} className="antallsliste__item">
                        <LoaderCard />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export function AntallslisteOversikt() {
    const { avtaler } = useLoaderData() as { avtaler: NumberBasedAgreementDto[] };
    return (
        <Side>
            <SideHeader
                title="Endre antall ansatte"
                lead="Hvis antall ansatte har endret seg mindre enn 10% trenger du ikke oppdatere avtalen før fornyelse."
            />
            <SideInnhold loadingText="Henter ansatte">
                <AnimatePresence mode="wait">
                    <React.Suspense fallback={<LoaderCards />}>
                        <Await resolve={avtaler}>{(data) => <AntallslisteComposer avtaler={data} />}</Await>
                    </React.Suspense>
                </AnimatePresence>
            </SideInnhold>
        </Side>
    );
}
