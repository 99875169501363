import { AvtaleProduktID } from "@features/agreements/avtale.model";

export type ProductCategory = {
    name: string;
    products: {
        [key: string]: {
            productName: string;
            productCode: AvtaleProduktID;
        };
    };
};

const ansatteProducts = {
    ANNEN_SYKDOM: { productCode: AvtaleProduktID.ANNEN_SYKDOM, productName: "Annen sykdom" },
    FRITIDSULYKKE: { productCode: AvtaleProduktID.KOLLEKTIV_ULYKKE_BEDRIFT, productName: "Ulykkesforsikring" },
    PERSONALGRUPPELIV: { productCode: AvtaleProduktID.PERSONALGRUPPELIV, productName: "Gruppelivsforsikring" },
    BEHANDLINGSFORSIKRING: {
        productCode: AvtaleProduktID.BEHANDLINGSFORSIKRING,
        productName: "Behandlingsforsikring"
    },
    REISE: { productCode: AvtaleProduktID.REISE_NERING, productName: "Reiseforsikring" },
    SYKELONN: { productCode: AvtaleProduktID.SYKELONN, productName: "Sykelønn" },
    ULYKKE_BARN: { productCode: AvtaleProduktID.KOLLEKTIV_ULYKKE_BARN, productName: "Ulykkesforsikring for barn" },
    YRKESSKADE: { productCode: AvtaleProduktID.YRKESSKADE, productName: "Yrkesskadeforsikring" }
} as const;

export const ansatteSection = {
    name: "Ansatte",
    products: ansatteProducts
};

const kjoretoyProducts = {
    ARBEIDSMASKIN: { productCode: AvtaleProduktID.TRAKTOR_ARBEIDSMASKIN_NERING, productName: "Arbeidsmaskin" },
    BIL: { productCode: AvtaleProduktID.BIL_NAERING, productName: "Bilforsikring" },
    BUSS: { productCode: AvtaleProduktID.BUSS, productName: "Bussforsikring" },
    BAT: { productCode: AvtaleProduktID.NERINGSBAT, productName: "Båtforsikring" },
    LASTEBIL: { productCode: AvtaleProduktID.LASTEBIL, productName: "Lastebilforsikring" },
    PROVESKILT: { productCode: AvtaleProduktID.ARSPROVEKJENNEMERKE, productName: "Prøveskiltforsikring" },
    TILHENGER: { productCode: AvtaleProduktID.TILHENGER_NERING, productName: "Tilhengerforsikring" },
    TRAKTOR: { productCode: AvtaleProduktID.TRAKTOR_ARBEIDSMASKIN_NERING, productName: "Traktorforsikring" }
} as const;

export const kjoretoySection = {
    name: "Kjøretøy",
    products: kjoretoyProducts
};

const driftProducts = {
    ANSVAR: { productCode: AvtaleProduktID.ANSVAR, productName: "Ansvarsforsikring" },
    DRIFTSTAP: { productCode: AvtaleProduktID.DRIFTSTAP, productName: "Driftstapforsikring" },
    BOLIGBYGG: { productCode: AvtaleProduktID.BOLIGBYGG_BORETTSLAG_SAMEIE, productName: "Boligbyggforsikring" },
    BORETTSLAG_SAMEIE: {
        productCode: AvtaleProduktID.BOLIGBYGG_BORETTSLAG_SAMEIE,
        productName: "Borettslag- og sameieforsikring"
    },
    CYBER: { productCode: AvtaleProduktID.CYBER, productName: "Cyberforsikring" },
    KRIMINALITETSFORSIKRING: {
        productCode: AvtaleProduktID.KRIMINALITETSFORSIKRING,
        productName: "Kriminalitetsforsikring"
    },
    NAERINGSBYGG: { productCode: AvtaleProduktID.NÆRINGSBYGG, productName: "Næringsbyggforsikring" },
    EIENDELER: { productCode: AvtaleProduktID.EIENDELER, productName: "Eiendelsforsikring" },
    PROSJEKT: { productCode: AvtaleProduktID.PROSJEKT_KONTRAKTSARBEIDER, productName: "Prosjektforsikring" },
    VERDISAK: { productCode: AvtaleProduktID.SPESIALFORSIKRING_NERING, productName: "Spesialforsikring" },
    TRANSPORT: { productCode: AvtaleProduktID.TRANSPORT_OMSETNING_VARER, productName: "Transportsforsikring" }
} as const;

export const driftSection = {
    name: "Drift og eiendom",
    products: driftProducts
};

// Disse produktene avventer vi svar på hvordan/om vi skal vise.
//
// LenkeId.avlingsskade, "Avling/innsatsmidler"
// LenkeId.bygninglandbruk, "Bygning landbruk"
// LenkeId.losorelandbruk, "Løsøre, avling for landbruk"
// LenkeId.produksjonlandbruk, "Produksjon landbruk"
// LenkeId.produksjondyrlandbruk, "Produksjonsdyr landbruk"
// LenkeId.ulykkebonde, "Ulykke bonde/familie")

const landbrukProducts = {
    AVLING: { productCode: AvtaleProduktID.LOSORE_AVLING_FOR_LANDBRUK, productName: "Avlingsskade" },
    BYGNING: { productCode: AvtaleProduktID.BYGNING_LANDBRUK, productName: "Driftsbygning" },
    LOSORE: {
        productCode: AvtaleProduktID.LOSORE_AVLING_FOR_LANDBRUK,
        productName: "Driftsløsøre og landbruksredskap"
    }
} as const;

export const landbrukSection = {
    name: "Landbruk",
    products: landbrukProducts
};

export const mostPopularProducts = {
    ANSVAR: { productCode: AvtaleProduktID.ANSVAR, productName: "Ansvarsforsikring" },
    TRAKTOR_ARBEIDSMASKIN_NERING: {
        productCode: AvtaleProduktID.TRAKTOR_ARBEIDSMASKIN_NERING,
        productName: "Arbeidsmaskin/Traktorforsikring"
    },
    BEHANDLINGSFORSIKRING: { productCode: AvtaleProduktID.BEHANDLINGSFORSIKRING, productName: "Behandlingsforsikring" },
    EIENDELER: { productCode: AvtaleProduktID.EIENDELER, productName: "Eiendelsforsikring" },
    PERSONALGRUPPELIV: { productCode: AvtaleProduktID.PERSONALGRUPPELIV, productName: "Gruppelivsforsikring" },
    NÆRINGSBYGG: { productCode: AvtaleProduktID.NÆRINGSBYGG, productName: "Næringsbyggforsikring " },
    TRANSPORT_OMSETNING_VARER: {
        productCode: AvtaleProduktID.TRANSPORT_OMSETNING_VARER,
        productName: "Transportforsikring"
    },
    YRKESSKADE: { productCode: AvtaleProduktID.YRKESSKADE, productName: "Yrkesskadeforsikring" }
} as const;
