import { Typography } from "@components/Typography";
import { TaskCard } from "@features/tasks/components/TaskCard/TaskCard";
import type { TaskResponseDto } from "@model/gen";

import styles from "./AgreementTasksSection.module.scss";

interface Props {
    agreementTasks: TaskResponseDto[];
}

export const AgreementTasksSection = ({ agreementTasks }: Props) => {
    return (
        <div className={styles.tasks} data-testId="tasks-section">
            <Typography variant="heading-2">Aktuelt</Typography>
            <div className={styles.taskList}>
                {agreementTasks.map((task, index) => (
                    <TaskCard key={index} task={task} />
                ))}
            </div>
        </div>
    );
};
