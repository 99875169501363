import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Checkbox } from "@fremtind/jkl-checkbox-react";
import { Loader } from "@fremtind/jkl-loader-react";
import { AgreementOverview } from "@model/gen";
import { useQueryClient } from "@tanstack/react-query";

import { PrimaryButton, SecondaryButton } from "../../../../components/Button";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Toast } from "../../../../components/Toast";
import { Rolle } from "../../../../model/Rolle";
import { FetchStatus } from "../../../../model/http.typer";
import { Trackingkey } from "../../../../tracking/tracking.model";
import { tilgangerQueryKeys, useGetTilganger, usePostTilganger } from "../../queries";

interface Props {
    values: any;
    save: (values: any) => void;
    goBack: () => void;
    goForward: () => void;
    avtalerFetchState: FetchStatus;
    avtaleTyper: Array<AgreementOverview["category"]>;
}

interface FormValues {
    roller?: Rolle[];
    navn: string;
}

export const TilgangerLeggTilBedrift = ({ values, save, goBack, avtalerFetchState, avtaleTyper, goForward }: Props) => {
    const queryClient = useQueryClient();
    const tilgangerQuery = useGetTilganger();
    const tilgangerMutation = usePostTilganger();
    const { register, handleSubmit, watch, setValue, getValues } = useForm<FormValues>({
        defaultValues: values
    });
    const [rollerInvalid, setRollerInvalid] = useState(false);

    const rollerValue = watch("roller");
    const organisasjonsnavn = watch("navn");

    useEffect(() => {
        if (!rollerValue) {
            setValue(
                "roller",
                tilgangerQuery.data?.tilgjengeligeOrganisasjonsTilganger.map((t) => t.rolle) ?? undefined
            );
        }
    }, [rollerValue, tilgangerQuery.data, setValue]);

    useEffect(() => {
        if (rollerValue?.length) {
            setRollerInvalid(false);
        }
    }, [rollerValue]);

    const onSubmit = async (formValues: FormValues) => {
        if (!formValues.roller?.length) {
            setRollerInvalid(true);
            return;
        }

        const roller = Array.isArray(formValues.roller) ? formValues.roller : [formValues.roller];

        const bedriftsData: {
            eksisterer: boolean;
            fraOrganisasjon: {
                organisasjonsnummer: string;
                navn: string;
            };

            roller: Rolle[];
        } = {
            eksisterer: values.eksisterer,
            fraOrganisasjon: {
                organisasjonsnummer: values.organisasjonsnummer,
                navn: values.navn
            },
            roller
        };

        tilgangerMutation.mutate(
            { data: bedriftsData },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: tilgangerQueryKeys.all });

                    save({
                        ...formValues
                    });

                    goForward();
                }
            }
        );
    };

    const handleBack = () => {
        save({ ...getValues() });
        goBack();
    };

    if (avtalerFetchState === "pending") {
        return <Loader textDescription="Henter avtaler" />;
    }

    if (!tilgangerQuery.data) {
        return <Loader textDescription="Henter tilganger" />;
    }

    return (
        <form name="ny tilgang bedrift" className="gi-tilganger-side" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="jkl-spacing-xl--bottom">Hvilke tilganger skal {organisasjonsnavn} ha?</h2>
            <label id="endre-help-label" htmlFor="gi-tilgang-rolle-valg" className="radio-label jkl-spacing-m--top">
                Velg forsikringstyper
            </label>
            {!!avtaleTyper.length && (
                <>
                    <fieldset id="gi-tilgang-rolle-valg" data-testautoid="gi-tilgang-rolle-valg">
                        {tilgangerQuery.data?.tilgjengeligeOrganisasjonsTilganger
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map(({ rolle, label }) => (
                                <Checkbox
                                    key={rolle}
                                    invalid={rollerInvalid}
                                    {...register("roller")}
                                    value={rolle}
                                    aria-describedby="roller-error-label"
                                >
                                    {label}
                                </Checkbox>
                            ))}
                        {rollerInvalid && (
                            <label
                                id="roller-error-label"
                                htmlFor="gi-tilgang-rolle-valg"
                                className="jkl-form-support-label jkl-form-support-label--error"
                                role="alert"
                            >
                                Du må velge forsikringstype
                            </label>
                        )}
                    </fieldset>
                </>
            )}

            <SkjemaFooter>
                <PrimaryButton
                    data-testid="gi-tilgang-bedrift-lagre"
                    dataTestautoid="gi-tilgang-bedrift-lagre"
                    track={[
                        { hendelse: Trackingkey.Knappetrykk, knappeId: "gi-tilgang-bedrift-lagre" },
                        { hendelse: Trackingkey.Tilgang, tilgang: "organisasjon", handling: "gi" }
                    ]}
                    loader={{ showLoader: tilgangerMutation.isPending, textDescription: "Lagrer tilgang bedrift" }}
                >
                    Lagre
                </PrimaryButton>

                <SecondaryButton
                    data-testid="gi-tilgang-bedrift-forrige"
                    dataTestautoid="gi-tilgang-bedrift-forrige"
                    className="jkl-spacing-l--right"
                    track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "gi-tilgang-bedrift-forrige" }}
                    type="button"
                    onClick={handleBack}
                >
                    ← Forrige
                </SecondaryButton>
            </SkjemaFooter>

            <Toast
                open={tilgangerMutation.isError}
                onClose={tilgangerMutation.reset}
                severity="error"
                title="Vi har gjort en feil"
            >
                Vi kunne ikke gi tilgang til {organisasjonsnavn} akkurat nå. Prøv en gang til eller kom tilbake senere.
            </Toast>
        </form>
    );
};
