import { Link } from "@fremtind/jkl-core";
import { formatValuta } from "@fremtind/jkl-formatters-util";
import { AgreementDetails } from "@model/gen";

interface Props {
    currentAgreement: AgreementDetails;
    newAgreement: AgreementDetails;
}

export const FornyelseNavnKanikkeEndres = ({ currentAgreement, newAgreement }: Props) => {
    if (currentAgreement.coreSystem === "PARIS") {
        <>
            Årlig pris endres til {formatValuta(newAgreement.annualPremium)}. Hvis du ønsker å gjøre endringer ta
            kontakt med din bankrådgiver. Vær oppmerksom på at dette kan føre til ny pris og faktura.
        </>;
    }

    return (
        <>
            Årlig pris endres til {formatValuta(newAgreement.annualPremium)}. Se{" "}
            <Link
                href={`/bedrift/api/dokumenter/avtale/pdf/${currentAgreement.id}/fornyelse`}
                target="_blank"
                rel="noopener noreferrer"
            >
                nytt avtaledokument
            </Link>{" "}
            for detaljer. Hvis du ønsker å gjøre endringer ta kontakt med din bankrådgiver. Vær oppmerksom på at dette
            kan føre til ny pris og faktura.
        </>
    );
};
