import { useFormContext } from "react-hook-form";
import { asDate } from "src/common/formatting";
import { Trackingkey } from "src/tracking";

import { PrimaryButton, SecondaryLinkButton } from "@components/Button";
import { SkjemaFooter } from "@components/SkjemaFooter";
import { Typography } from "@components/Typography";
import { useAgreementDetails } from "@features/agreements/agreements.queries";
import { formatDate } from "@fremtind/jkl-formatters-util";
import { ErrorMessageBox, WarningMessageBox } from "@fremtind/jkl-message-box-react";

import { MileageInput } from "../../components";
import { AgreementUpdateStep, MileageInputs } from "../Mileage";

const MILEAGE_FIELD_NAME = "Kilometerstand";
const MILEAGE_LAST_READ_FIELD_NAME = "Siste avlesning";

export const UpdateMileage: React.FC<AgreementUpdateStep> = ({
    isMileageUpdatable,
    agreementId,
    onSubmit,
    onFrem,
    agreementUpdateMutation,
    hasUpdatedMileage,
    error
}) => {
    const { data: agreement } = useAgreementDetails(agreementId);
    const { isPending } = agreementUpdateMutation;
    const { handleSubmit } = useFormContext<MileageInputs>();

    const vehicle = agreement?.objects[0];

    const currentMileage = vehicle?.fields.find((field) => field.description === MILEAGE_FIELD_NAME)?.value;
    const lastReadDate = vehicle?.fields.find((field) => field.description === MILEAGE_LAST_READ_FIELD_NAME)?.value;

    const lastReadAsDate = asDate(lastReadDate || "");

    return (
        <form onSubmit={handleSubmit(() => onSubmit(onFrem))}>
            <Typography className="jkl-spacing-xs--bottom" component="h2" variant="heading-3">
                Endre kilometerstand
            </Typography>
            <Typography className="jkl-spacing-l--bottom" component="p" variant="body">
                Når du oppdaterer kilometerstanden hjelper det deg og oss med å ha kontroll på at du ikke har kjørt
                lenger enn det du er dekket for. Kilometerstand vil ha betydning ved en eventuell skadeutbetaling.
            </Typography>
            {!isMileageUpdatable && (
                <WarningMessageBox title="Kilometerstand kan ikke endres">
                    Kilometerstanden kan kun endres én gang i året. Den ble sist avlest {formatDate(lastReadAsDate)} til{" "}
                    {currentMileage}.
                </WarningMessageBox>
            )}
            {isMileageUpdatable && <MileageInput lastChecked={lastReadDate} />}

            {error && (
                <ErrorMessageBox className="jkl-spacing-l--top" title="En feil har oppstått">
                    {error}
                </ErrorMessageBox>
            )}
            <SkjemaFooter className="update-insurance__form__footer">
                {hasUpdatedMileage && (
                    <PrimaryButton
                        dataTestautoid="oppdater-kmstand"
                        track={{
                            hendelse: Trackingkey.Knappetrykk,
                            knappeId: "oppdater-kmstand"
                        }}
                        loader={{
                            showLoader: isPending,
                            textDescription: "Oppdaterer kilometerstand"
                        }}
                        type="submit"
                    >
                        Send inn
                    </PrimaryButton>
                )}
                <SecondaryLinkButton to={`/forsikringer/${agreementId}`} className="jkl-spacing-l--right">
                    Tilbake
                </SecondaryLinkButton>
            </SkjemaFooter>
        </form>
    );
};
