import { useEffect } from "react";

import { useFormContext } from "react-hook-form";

import { useQueryClient } from "@tanstack/react-query";

import { SigneringFormData, SigneringStegProps } from ".";
import { Typography } from "../../../components/Typography";
import { signeringQueryKey } from "../queries";

export const Signer = ({ onFrem, onRestart }: SigneringStegProps) => {
    const form = useFormContext<SigneringFormData>();

    const queryClient = useQueryClient();

    useEffect(() => {
        function handleMessage(event: MessageEvent) {
                switch (event.data) {
                    case "SIGNING_COMPLETE": {
                        queryClient.invalidateQueries({ queryKey: [signeringQueryKey.all] });
                        onFrem();
                        break;
                    }
                    case "SIGNING_ABORT":
                    case "SIGNING_ERROR": {
                        onRestart();
                        break;
                    }
                }
        }

        // Meldingen blir sendt fra /public/static/signingComplete.html
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [onFrem, onRestart, queryClient]);

    return (
        <div>
            <Typography variant="heading-1" component="h2" className="jkl-spacing-xl--bottom">
                Signer dokument
            </Typography>

            <iframe
            className="border-none h-[425px] w-[600px] max-w-full"
                title="signering"
                src={form.getValues().signering.signUrl}
            />
        </div>
    );
};
