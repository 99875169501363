import { ReactNode } from "react";

import { toNorwegianDateFormat } from "src/common/formatting";

import { InfoTag, WarningTag } from "@fremtind/jkl-tag-react";
import type { AgreementOverview } from "@model/gen";

import { agreementStatusIsAccept, agreementStatusIsOnHold, agreementStatusIsRenewal } from "../avtale.utils";

interface Props {
    agreement: AgreementOverview;
    agreementsToDisplay: AgreementOverview[];
    i: number;
}

export const RenderTags = ({ agreement, agreementsToDisplay, i }: Props) => {
    const statusRenewalTag = agreementStatusIsRenewal(agreement) && (
        <InfoTag className="agreement-card__tag">Fornyelse</InfoTag>
    );
    const statusAcceptTag = agreementStatusIsOnHold(agreement) ? (
        <InfoTag className="agreement-card__tag">Avtale på vent </InfoTag>
    ) : agreementStatusIsAccept(agreement) ? (
        <WarningTag className="agreement-card__tag">
            Gyldig fra {agreement.startDate && toNorwegianDateFormat(agreement.startDate)}
        </WarningTag>
    ) : null;

    const tagsToDisplay: Array<ReactNode> = [statusAcceptTag, statusRenewalTag].filter((tag) => tag);

    if (tagsToDisplay.length > 0) {
        return <div className={"flex gap-16"}>{tagsToDisplay}</div>;
    }

    if (i === 0 || i + 1 === agreementsToDisplay.length) {
        return null;
    }

    if (
        (i % 2 === 1 &&
            (agreementStatusIsRenewal(agreementsToDisplay[i - 1]) ||
                agreementStatusIsAccept(agreementsToDisplay[i - 1]) ||
                agreementStatusIsOnHold(agreementsToDisplay[i - 1]))) ||
        (i % 2 === 0 &&
            (agreementStatusIsRenewal(agreementsToDisplay[i + 1]) ||
                agreementStatusIsAccept(agreementsToDisplay[i + 1]) ||
                agreementStatusIsOnHold(agreementsToDisplay[i + 1])))
    ) {
        return <div className="agreement-card__tag agreement-card__tag--filler" />;
    }

    return null;
};
