import { BMRoute } from "src/sider";

import { Mileage, MileagePerYear } from "./views";

export const agreementUpdateRoutes: BMRoute[] = [
    {
        path: "administrer",
        children: [
            {
                path: "kilometerstand",
                element: <Mileage />,
                breadcrumbLabel: "Oppdater kilometerstand"
            },
            {
                path: "kjorelengde",
                element: <MileagePerYear />,
                breadcrumbLabel: "Oppdater kjørelengde"
            }
        ]
    }
];
