"use client";

import React, { useState } from "react";

import { Trackingkey, track } from "src/tracking";

import { Typography } from "@components/Typography";
import { useAgreementField } from "@features/agreements/preview/hooks/useAgreementField";
import { Accordion, AccordionItem } from "@fremtind/jkl-accordion-react";
import { GreenCheckIcon } from "@fremtind/jkl-icons-react";
import { RadioButton, RadioButtonGroup } from "@fremtind/jkl-radio-button-react";
import {
    ExpandableTableRow,
    ExpandableTableRowController,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "@fremtind/jkl-table-react";
import type { AgreementDetails } from "@model/gen";

import "./CoverageComparisonTable.scss";
import { coverageTerms, coveredIncidents } from "./data";

interface Props {
    agreementDetails: AgreementDetails;
}

const DesktopCoverages: React.FC = () => (
    <Table fullWidth className="agreement-details__coverage-table__desktop">
        <TableHead>
            <TableRow>
                <TableHeader className="cell column-1">Dekning</TableHeader>
                {coverageTerms.map((coverage, index) => (
                    <TableHeader className={`cell column-${index + 2}`} key={coverage.title}>
                        {coverage.title}
                    </TableHeader>
                ))}
            </TableRow>
        </TableHead>
        <TableBody>
            {coveredIncidents.map((incident) => (
                <ExpandableTableRow
                    expandedChildren={
                        <div className="agreement-details__coverage-table__desktop__row__expanded">
                            {incident.description}
                            <span className="jkl-sr-only">
                                Dekkes i følgende dekninger:{" "}
                                {coverageTerms
                                    .filter((coverage) => coverage.terms.find((term) => term.title === incident.title))
                                    .map((coverage) => coverage.title)
                                    .join(", ")}
                            </span>
                        </div>
                    }
                    onToggle={(expanded) => {
                        if (expanded) {
                            track({
                                hendelse: Trackingkey.Utvid,
                                id: incident.title,
                                kategori: "V05-dekningstabell"
                            });
                        }
                    }}
                    colSpan={coverageTerms.length + 1}
                    key={incident.title}
                    className="agreement-details__coverage-table__desktop__row"
                >
                    <ExpandableTableRowController className="cell column-1">
                        {incident.title}
                    </ExpandableTableRowController>

                    {coverageTerms.map((coverage, index) => (
                        <TableCell key={coverage.title} className={`cell column-${index + 2}`}>
                            {coverage.terms.find((term) => term.title === incident.title) && <GreenCheckIcon />}
                        </TableCell>
                    ))}
                </ExpandableTableRow>
            ))}
        </TableBody>
    </Table>
);

const MobileCoverages: React.FC<Props> = ({ agreementDetails }) => {
    const [chosenCoverage, setChosenCoverage] = useState(useAgreementField(agreementDetails).car.coverage);

    const coveredTerms = coverageTerms.find((term) => term.title.includes(chosenCoverage))?.terms;

    const notCoveredTerms = coveredIncidents.filter(
        (incident) => !coveredTerms?.find((term) => term.title === incident.title)
    );

    return (
        <section className="agreement-details__coverage-table__mobile">
            <RadioButtonGroup
                legend="Hvilken dekning vil du se?"
                name="hasCarLoan"
                labelProps={{ variant: "medium" }}
                value={chosenCoverage}
                defaultValue={useAgreementField(agreementDetails).car.coverage}
                onChange={(e) => setChosenCoverage(e.target.value.toString())}
                className="agreement-details__coverage-table__mobile__radio-group"
            >
                {coverageTerms.map((term) => (
                    <RadioButton
                        key={term.title}
                        //checked={term.title.includes(useAgreementField(agreementDetails).car.coverage)}
                        value={term.title}
                        className="agreement-details__coverage-table__mobile__radio-group__radio"
                        data-selected={term.title}
                    >
                        {term.title}
                    </RadioButton>
                ))}
            </RadioButtonGroup>
            <div>
                <section className="agreement-details__coverage-table__mobile__covered">
                    <h3 className="jkl-spacing-l--bottom">Dekker</h3>
                    <Accordion>
                        {coveredTerms &&
                            coveredTerms.map((incident) => (
                                <AccordionItem
                                    onClick={(e) => {
                                        if (e.target) {
                                            track({
                                                hendelse: Trackingkey.Utvid,
                                                id: incident.title,
                                                kategori: "V05-dekningstabell"
                                            });
                                        }
                                    }}
                                    key={incident.title}
                                    title={incident.title}
                                >
                                    <div>{incident.description}</div>
                                </AccordionItem>
                            ))}
                    </Accordion>
                </section>

                {!!notCoveredTerms.length && (
                    <section className="agreement-details__coverage-table__mobile__not-covered">
                        <h3 className="jkl-spacing-l--bottom">Dekker ikke</h3>
                        <Accordion>
                            {notCoveredTerms.map((incident) => (
                                <AccordionItem key={incident.title} title={incident.title}>
                                    {incident.description}
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </section>
                )}
            </div>
        </section>
    );
};

interface CoverageComparisonTableProps {
    agreementDetails: AgreementDetails;
}

export const CoverageComparisonTable: React.FC<CoverageComparisonTableProps> = ({ agreementDetails }) => {
    return (
        <section className="agreement-details__coverage-table">
            <Typography
                variant="heading-2"
                className="agreement-details__coverage-table__heading jkl-spacing-l--bottom"
            >
                Sammenlign dekningene
            </Typography>
            <DesktopCoverages />
            <MobileCoverages agreementDetails={agreementDetails} />
        </section>
    );
};
