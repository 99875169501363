import { useFormContext, useWatch } from "react-hook-form";
import { Trackingkey } from "src/tracking";

import { PrimaryButton, SecondaryLinkButton } from "@components/Button";
import { SkjemaFooter } from "@components/SkjemaFooter";
import { Typography } from "@components/Typography";
import {
    MILEAGE_FIELD_NAME,
    MILEAGE_LAST_CHECKED_FIELD_NAME,
    MILEAGE_PER_YEAR_FIELD_NAME,
    findField,
    updatesResponseToSelectItems
} from "@features/agreement-updates/agreementupdates.utils";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { MileageInput, MileagePerYearInput } from "../../components";
import { AgreementUpdateStep, MileagePerYearInputs } from "../MileagePerYear";

export const UpdateMileage: React.FC<AgreementUpdateStep> = (props) => {
    const { updatableFields, agreementId, onFrem, onSubmit, initialValues, agreementUpdateMutation, error } = props;

    const { isPending } = agreementUpdateMutation;

    const mileagePerYearField = findField(updatableFields, MILEAGE_PER_YEAR_FIELD_NAME);
    const mileageField = findField(updatableFields, MILEAGE_FIELD_NAME);
    const lastCheckedField = findField(updatableFields, MILEAGE_LAST_CHECKED_FIELD_NAME);

    const availableOptions = mileagePerYearField?.availableOptions;

    const { handleSubmit } = useFormContext<MileagePerYearInputs>();
    const { mileage, mileagePerYear } = useWatch<MileagePerYearInputs>();

    if (!availableOptions?.length) return null;

    const selectItems = updatesResponseToSelectItems(availableOptions);
    const lastCheckedDate = lastCheckedField?.selected?.value;

    const hasUpdatedMileage = mileage !== initialValues?.mileage;
    const hasUpdatedMileagePerYear = mileagePerYear !== initialValues?.mileagePerYear;

    let hasUpdatedFields = hasUpdatedMileagePerYear;

    if (mileageField) {
        hasUpdatedFields = hasUpdatedFields && hasUpdatedMileage;
    }

    const mileageText = mileageField ? "Du må oppgi kilometerstand for å kunne endre kjørelengden." : "";

    return (
        <form onSubmit={handleSubmit(() => onSubmit(false, onFrem))}>
            <Typography className="jkl-spacing-xs--bottom" component="h2" variant="heading-3">
                Endre kjørelengde
            </Typography>
            <Typography className="jkl-spacing-l--bottom" component="p" variant="body">
                Endrer du avtalt kjørelengde midt i forsikringsåret, påvirker det prisen både forover og bakover i tid.
                Reduserer du kjørelengden får du penger tilbake, øker du den må du betale mer. {mileageText}
            </Typography>
            {!!mileageField && <MileageInput className="jkl-spacing-xl--bottom" lastChecked={lastCheckedDate} />}

            <MileagePerYearInput selectItems={selectItems} />
            {error && (
                <ErrorMessageBox className="jkl-spacing-l--top" title="En feil har oppstått">
                    {error}
                </ErrorMessageBox>
            )}
            <SkjemaFooter className="update-insurance__form__footer">
                {hasUpdatedFields && (
                    <PrimaryButton
                        type="submit"
                        dataTestautoid="gi-tilgang-bedrift-neste"
                        data-testid="gi-tilgang-bedrift-neste"
                        track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "oppdater-kjorelengde-neste" }}
                        loader={{
                            showLoader: isPending,
                            textDescription: "Sjekker pris"
                        }}
                    >
                        Beregn ny pris
                    </PrimaryButton>
                )}
                <SecondaryLinkButton to={`/forsikringer/${agreementId}`} className="jkl-spacing-l--right">
                    Avbryt
                </SecondaryLinkButton>
            </SkjemaFooter>
        </form>
    );
};
