import type React from "react";
import { useCallback } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";

import { Typography } from "@components/Typography";
import { LoaderCard } from "@features/antallsliste/components";
import { useFetchDokumentListe } from "@features/dokumenter/queries";
import { NavCard } from "@fremtind/jkl-card-react";
import type { AgreementDetails, DocumentDto } from "@model/gen";

import "./AgreementDocumentsList.scss";

interface Props {
    agreementDetails: AgreementDetails;
    showDocumentsPageLink?: boolean;
    showLatestInvoice?: boolean;
    showAsNavCard?: boolean;
}

const byAvtaleNummerFilter = (avtalenummer: string) => (dokument: DocumentDto) =>
    !avtalenummer ? true : (dokument.agreementNumbers ?? []).map((a) => a).includes(avtalenummer);

const byDokumentTypeFilter = (dokumentType: string) => (dokument: DocumentDto) =>
    !dokumentType ? true : dokument.documentType === dokumentType;

export const AgreementDocumentsList: React.FC<Props> = ({
    agreementDetails,
    showDocumentsPageLink,
    showLatestInvoice,
    showAsNavCard
}) => {
    const dagensDato = new Date();
    const fjorAaret = new Date();
    fjorAaret.setFullYear(dagensDato.getFullYear() - 1);

    const { query } = useFetchDokumentListe(fjorAaret, dagensDato, true);

    const latestInvoiceFilter = useCallback(
        (liste: DocumentDto[]) =>
            liste
                .filter(byAvtaleNummerFilter(agreementDetails.agreementId))
                .filter(byDokumentTypeFilter("Betalingsdokument"))[0],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const latestInvoice = useCallback(
        (liste: DocumentDto[]) => {
            return latestInvoiceFilter(liste);
        },
        [latestInvoiceFilter]
    );

    return (
        <section className="agreement-details__documents">
            <Typography variant="heading-2" className="agreement-details__documents__heading">
                Dokumenter
            </Typography>
            {showAsNavCard ? (
                <AnimatePresence initial={false}>
                    <motion.div
                        key="dokument-liste"
                        initial={{ opacity: 0 }}
                        animate={{ transition: { delay: 0.3 }, opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="agreement-details__documents__list"
                    >
                        <li>
                            <NavCard
                                title={`Avtaledokument`}
                                href={`/bedrift/api/dokumenter/avtale/pdf/${agreementDetails.id}`}
                                target="_blank"
                                aria-label="Åpne avtaledokument"
                                type="document"
                            >
                                Forsikringsbevis og vilkår
                            </NavCard>
                        </li>
                        <li>
                            <NavCard
                                title={`Alle dokumenter`}
                                href={`dokumenter?avtaleFilter=${agreementDetails.agreementId}`}
                                aria-label="Gå til dokumenter"
                            >
                                Filtrert på denne avtalen
                            </NavCard>
                        </li>

                        {query.data && showLatestInvoice && latestInvoice(query.data?.entity ?? []) && (
                            <li>
                                <NavCard
                                    key={latestInvoice(query.data?.entity ?? []).id}
                                    title={`Siste faktura`}
                                    href={`/bedrift/api/dokumenter/document/${latestInvoice(query.data?.entity ?? []).id}`}
                                    target="_blank"
                                    description={`Sendt ${new Date(
                                        latestInvoice(query.data?.entity ?? []).created!
                                    ).toLocaleDateString("nb-NO", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric"
                                    })}`}
                                    aria-label="Åpne betalingsdokument"
                                />
                            </li>
                        )}
                    </motion.div>
                </AnimatePresence>
            ) : (
                <AnimatePresence>
                    <motion.div
                        key="dokument-liste-skeleton"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="agreement-details__documents__list"
                    >
                        <LoaderCard height={128} />
                        <LoaderCard height={128} />
                    </motion.div>
                </AnimatePresence>
            )}

            {showDocumentsPageLink && (
                <div className="agreement-details__documents__links">
                    <NavLink
                        to={`/dokumenter?avtaleFilter=${agreementDetails.agreementId}&datoFra=1688553759724&datoTil=1720176159724`}
                        className="jkl-nav-link"
                    >
                        Dokumenter for avtalen
                    </NavLink>
                    <NavLink to="/dokumenter" className="jkl-nav-link">
                        Alle dokumenter
                    </NavLink>
                </div>
            )}
        </section>
    );
};
