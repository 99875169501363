import { AnimatePresence, motion } from "framer-motion";

import { useAgreementList } from "@features/agreements/agreements.queries";
import { InsuredTable } from "@features/navneliste/components/Oversikt";
import { useMedlemDetaljer, useMedlemList } from "@features/navneliste/queries/medlem.queries";
import { SkeletonAnimation } from "@fremtind/jkl-loader-react";
import { InfoMessageBox } from "@fremtind/jkl-message-box-react";

import { PrimaryLinkButton, SecondaryLinkButton } from "../components/Button";
import { Side, SideHeader, SideInnhold } from "../components/Side";
import { BmSkeletonTable } from "../components/Skeleton";
import { Typography } from "../components/Typography";
import { Trackingkey, track } from "../tracking";
import "./MedlemOversikt.scss";

const Loader = () => (
    <AnimatePresence>
        <motion.div
            key="medlemmer-liste-skeleton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <SkeletonAnimation>
                <BmSkeletonTable contentHeight={80} colsHalfWidth headingWidths={[80, 170]} columns={5} rows={10} />
            </SkeletonAnimation>
        </motion.div>
    </AnimatePresence>
);

export function Medlemmer() {
    const { data, isError, isFetching } = useMedlemList();

    const avtalelisteQuery = useAgreementList();
    const medlemAvtaler =
        avtalelisteQuery.data?.agreements.filter((avtale) => avtale.category === "FORENING_FORBUND") ?? [];
    const navnebaserteAvtaler = medlemAvtaler.filter((avtale) => avtale.groupInsuranceType === "NAME_BASED");

    track({
        hendelse: Trackingkey.SeListe,
        type: "medlemmer",
        antall: data?.entity.medlemmer.length ?? 0
    });

    return (
        <Side>
            <SideHeader
                title="Medlemmer"
                lead="Listen viser deg medlemmer som er meldt inn på personforsikringer med navn. Du kan klikke på navnet i tabellen for mer informasjon."
            />
            <SideInnhold className="medlemmer" query={avtalelisteQuery}>
                {navnebaserteAvtaler.length === 0 ? (
                    <InfoMessageBox title="Ingen personforsikringer med navn">
                        Hvis du vil gjøre endringer på antallbaserte forsikringer, må du kontakte rådgiver.
                    </InfoMessageBox>
                ) : (
                    <>
                        <div className="medlemmer__actions">
                            <PrimaryLinkButton
                                to="meld-inn"
                                onClick={() => {
                                    track([
                                        {
                                            hendelse: Trackingkey.Knappetrykk,
                                            knappeId: "medlemmer-meld-inn-knapp"
                                        }
                                    ]);
                                }}
                                data-testautoid="ansatte-meld-inn-knapp"
                            >
                                Meld inn medlem
                            </PrimaryLinkButton>
                            <SecondaryLinkButton
                                to="meld-ut"
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "medlemmer-meld-ut-knapp"
                                    });
                                }}
                                data-testautoid="medlemmer-meld-ut-knapp"
                            >
                                Meld ut medlem
                            </SecondaryLinkButton>
                        </div>

                        <div className="medlemmer__top-info">
                            <div className="medlemmer__container">
                                <p className="jkl-heading-3">Medlemmer på antallsliste</p>
                                <p className="jkl-body">
                                    Hvis du vil gjøre endringer på antallslisten, må du kontakte rådgiver.
                                </p>
                            </div>
                        </div>
                        {isFetching && <Loader />}
                        {isError && (
                            <InfoMessageBox title="Feil ved henting av medlemmer">
                                Vennligst prøv igjen senere eller kontakt rådgiver
                            </InfoMessageBox>
                        )}
                        {!isFetching && data && (
                            <AnimatePresence initial={false}>
                                <motion.div
                                    key="medlemmer-liste"
                                    initial={{ opacity: 0 }}
                                    animate={{ transition: { delay: 0.3 }, opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    {data?.entity && (
                                        <>
                                            <InsuredTable
                                                headers={data.entity.headere}
                                                rows={data.entity.medlemmer}
                                                useDetails={useMedlemDetaljer}
                                                exportLink="medlemsliste"
                                                meldUtButtonProps={{
                                                    to: (insured) => `meld-ut/${insured.indeks}`,
                                                    tracking: () => {
                                                        track({
                                                            hendelse: Trackingkey.Knappetrykk,
                                                            knappeId: "medlemmer-meld-ut-knapp"
                                                        });
                                                    }
                                                }}
                                                meldInnButtonProps={{
                                                    to: (medlem) => `meld-inn/${medlem.indeks}`,
                                                    tracking: () => {
                                                        track({
                                                            hendelse: Trackingkey.Knappetrykk,
                                                            knappeId: "medlemmer-meld-inn-knapp"
                                                        });
                                                    }
                                                }}
                                            />

                                            <Typography
                                                variant="heading-2"
                                                className="jkl-spacing-4xl--top text-center"
                                                hidden={!!data.entity.medlemmer.length}
                                            >
                                                Ingen medlemmer er meldt inn enda...
                                            </Typography>
                                        </>
                                    )}
                                </motion.div>
                            </AnimatePresence>
                        )}
                    </>
                )}
            </SideInnhold>
        </Side>
    );
}
