import { useRef } from "react";

import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import { toNorwegianDateFormat } from "src/common/formatting";
import { Trackingkey, track } from "src/tracking";

import { PrimaryButton, PrimaryLinkButton, SecondaryButton } from "@components/Button";
import { SkjemaFooter } from "@components/SkjemaFooter";
import { Typography } from "@components/Typography";
import {
    MILEAGE_PER_YEAR_FIELD_NAME,
    deriveMileageFromLabel,
    findField
} from "@features/agreement-updates/agreementupdates.utils";
import { useAgreementDetails } from "@features/agreements/agreements.queries";
import { formatAvstand, formatValuta } from "@fremtind/jkl-formatters-util";
import { ListItem, UnorderedList } from "@fremtind/jkl-list-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { SummaryTable, SummaryTableRow } from "@fremtind/jkl-summary-table-react";

import { AgreementUpdateStep, MileagePerYearInputs } from "../MileagePerYear";

export const PriceSummary: React.FC<AgreementUpdateStep> = ({
    onFrem,
    onTilbake,
    agreementUpdateMutation,
    updatableFields,
    onSubmit,
    error,
    hasSuccessFullyUpdated,
    isMileageUpdatable
}) => {
    const { data, isPending } = agreementUpdateMutation;
    const { agreementId } = useParams();
    const { data: agreement } = useAgreementDetails(agreementId || "");
    const { getValues, handleSubmit } = useFormContext<MileagePerYearInputs>();

    const currentPrice = agreement?.annualPremium || 0;
    const newPrice = data?.annualPremium || 0;

    const currentPriceRef = useRef(currentPrice);
    const newPriceRef = useRef(newPrice);

    if (data?.annualPremium) {
        newPriceRef.current = data?.annualPremium;
    }

    const priceDifference = Number(newPriceRef.current) - Number(currentPriceRef.current);
    const { mileagePerYear, mileage } = getValues();

    const fieldObject = findField(updatableFields, MILEAGE_PER_YEAR_FIELD_NAME);

    const mileagePerYearLabel = fieldObject?.availableOptions?.find((option) => {
        return option.valueId === mileagePerYear;
    })?.value;

    const mileagePerYearAsNumber = deriveMileageFromLabel(mileagePerYearLabel || "");

    return (
        <form onSubmit={handleSubmit(() => onSubmit(true, onFrem))}>
            <Typography className="jkl-spacing-xl--bottom" component="h2" variant="heading-3">
                {hasSuccessFullyUpdated ? "Avtalen er oppdatert" : "Oppsummering"}
            </Typography>

            <section>
                {isMileageUpdatable && (
                    <>
                        <Typography className="jkl-spacing-xs--bottom" component="h3" variant="heading-4">
                            Ny kilometerstand
                        </Typography>
                        <Typography className="jkl-spacing-xl--bottom" component="p" variant="body">
                            {formatAvstand(mileage || 0)}
                        </Typography>
                    </>
                )}

                <>
                    <Typography className="jkl-spacing-xs--bottom" component="h3" variant="heading-4">
                        Ny kjørelengde
                    </Typography>
                    <Typography className="jkl-spacing-xl--bottom" component="p" variant="body">
                        {mileagePerYearLabel}
                    </Typography>
                </>
            </section>
            <section>
                <UnorderedList className="jkl-spacing-xl--bottom">
                    <ListItem>
                        Gjelder {toNorwegianDateFormat(agreement?.startDate)} -{" "}
                        {toNorwegianDateFormat(agreement?.endDate)}
                    </ListItem>
                    <ListItem>
                        Du kan kjøre {formatAvstand(mileagePerYearAsNumber)} frem til{" "}
                        {toNorwegianDateFormat(agreement?.endDate)}
                    </ListItem>
                    <ListItem>Prisdifferansen faktureres/krediteres</ListItem>
                    <ListItem>Trafikkforsikringsavgift inkludert</ListItem>
                </UnorderedList>
            </section>
            <section>
                <SummaryTable
                    header={["Prisendring", "pris"]}
                    body={
                        <>
                            <SummaryTableRow
                                header="Nåværende pris"
                                content={`${formatValuta(currentPriceRef.current)} kr/år`}
                            />
                            <SummaryTableRow header="Prisendring" content={`${formatValuta(priceDifference)} kr/år`} />
                        </>
                    }
                    footer={<SummaryTableRow header="Ny pris" content={`${formatValuta(newPriceRef.current)} kr/år`} />}
                />
            </section>
            {error && (
                <ErrorMessageBox className="jkl-spacing-l--top" title="En feil har oppstått">
                    {error}
                </ErrorMessageBox>
            )}
            <SkjemaFooter className="update-insurance__form__footer">
                {hasSuccessFullyUpdated ? (
                    <PrimaryLinkButton
                        to={`/forsikringer/${agreementId}`}
                        onClick={() =>
                            track({ hendelse: Trackingkey.Knappetrykk, knappeId: "oppdater-kjorelengde-send-inn" })
                        }
                    >
                        Tilbake til avtalen
                    </PrimaryLinkButton>
                ) : (
                    <>
                        <PrimaryButton
                            dataTestautoid="oppdater-kjorelengde-send-inn"
                            track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "oppdater-kjorelengde-send-inn" }}
                            type="submit"
                            loader={{
                                showLoader: isPending,
                                textDescription: "Oppdaterer kjørelengde"
                            }}
                        >
                            Send inn
                        </PrimaryButton>
                        <SecondaryButton
                            dataTestautoid="oppdater-kjorelengde-tilbake"
                            track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "oppdater-kjorelengde-tilbake" }}
                            onClick={() => onTilbake()}
                            className="jkl-spacing-l--right"
                        >
                            Tilbake
                        </SecondaryButton>
                    </>
                )}
            </SkjemaFooter>
        </form>
    );
};
