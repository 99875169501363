import { model } from "@features/navneliste";

export const getMuligeAvtalerForUtmelding = (avtaler: model.OppdateringStatusAvtale[]) => {
    return avtaler.filter(
        (avtale) =>
            !avtale.innmeldingMulig &&
            avtale.utmeldingMulig &&
            avtale.utmeldingStatusKode === model.UtmeldingStatusKode.UTMELDING_MULIG
    );
};

export const findPerson = (indeks: string, insuredList?: model.Person[]) => {
    return insuredList?.find((a) => a.indeks === indeks);
};

export const resolvePersonName = (insured?: model.Person) => {
    if (!insured) {
        return "";
    }

    return `${insured.fornavn} ${insured.etternavn}`;
};

export const partitionEndredeAvtaler = (avtaler: model.OppdateringResponse["avtaler"]) =>
    avtaler.reduce<model.OppdateringResponse["avtaler"][]>(
        (acc, avtale) => {
            switch (avtale.oppdateringStatus) {
                case "OPPDATERING_FEILET": {
                    return [acc[0], [...acc[1], avtale]];
                }
                default: {
                    return [[...acc[0], avtale], acc[1]];
                }
            }
        },
        [[], []]
    );

export function resolveArbeidsdyktighet(arbeidsdyktig: "Ja" | "Nei"): boolean | undefined {
    switch (arbeidsdyktig) {
        case "Ja":
            return true;
        case "Nei":
            return false;
        default:
            return undefined;
    }
}
