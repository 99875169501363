import { useEffect, useState } from "react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useScreen } from "src/context/UseScreenContext";
import { Trackingkey, track } from "src/tracking";

import { TabType } from "@features/invoice/views/InvoiceOverview/model";
import { formatDate, formatValuta } from "@fremtind/jkl-formatters-util";
import { usePreviousValue } from "@fremtind/jkl-react-hooks";
import { Select } from "@fremtind/jkl-select-react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@fremtind/jkl-table-react";
import { InvoiceDto } from "@model/gen";

import { asDate } from "../../../../common/formatting";
import { ExcelExportLink } from "../../../../components/ExcelExportLink";
import { SearchField } from "../../../../components/SearchField";
import { Typography } from "../../../../components/Typography";
import { StatusTag } from "../StatusTag";
import "./InvoiceOverviewTable.scss";

const SHOW_ALL_INVOICES = "alle";

interface Props {
    invoices: InvoiceDto[];
    activeTab: TabType;
}

const currentYear = new Date().getFullYear().toString();

const getSelectedPeriod = (
    searchParams: URLSearchParams,
    availablePeriods: Array<string | { label: string; value: string }>
): string => {
    const searchParamsPeriode = searchParams.get("periode");
    if (searchParamsPeriode) {
        return searchParamsPeriode;
    } else if (availablePeriods.includes(currentYear)) {
        return currentYear;
    } else {
        return SHOW_ALL_INVOICES;
    }
};

export const InvoiceOverviewTable = ({ invoices, activeTab }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { isSmallDevice } = useScreen();

    const availablePeriods = [
        { label: "Vis alt", value: SHOW_ALL_INVOICES },
        ...Array.from(
            new Set(
                invoices
                    .filter((invoice) => Boolean(invoice.fakturaDato))
                    .map((invoice) => asDate(invoice.fakturaDato!).getFullYear().toString())
            )
        )
            .sort()
            .reverse()
    ];

    const selectedPeriod = getSelectedPeriod(searchParams, availablePeriods);

    const handlePeriodChange = (value: string) => {
        const sp = new URLSearchParams(searchParams);
        sp.set("periode", value);
        setSearchParams(sp, { replace: true, preventScrollReset: true });
    };

    const [invoiceNumberFilter, setInvoiceNumberFilter] = useState(searchParams.get("fakturanummer") ?? "");
    const updateInvoiceNumberSearchParam = (input = invoiceNumberFilter) => {
        const sp = new URLSearchParams(searchParams);

        if (sp.get("fakturanummer") === input) {
            return;
        }

        if (input) {
            sp.set("fakturanummer", input);
        } else {
            sp.delete("fakturanummer");
        }
        setSearchParams(sp, { replace: true, preventScrollReset: true });
    };
    const handleInvoiceNumberSearchChange = (value: string) => {
        track({
            hendelse: Trackingkey.Søk,
            tekst: value,
            tekstlengde: value.length,
            treff: invoices.filter((invoice) => invoice.fakturaId.includes(value)).length
        });
        setInvoiceNumberFilter(value);
        if (!value) {
            updateInvoiceNumberSearchParam("");
        }
    };

    const previouslySelectedTab = usePreviousValue(activeTab);
    useEffect(() => {
        if (previouslySelectedTab && previouslySelectedTab !== activeTab) {
            const sp = new URLSearchParams(searchParams);
            sp.delete("periode");
            sp.delete("fakturanummer");
            setSearchParams(sp, { replace: true, preventScrollReset: true });
            setInvoiceNumberFilter("");
        }
    }, [activeTab, previouslySelectedTab, searchParams, setSearchParams]);

    const filteredInvoices = invoices
        // filter by period
        .filter((invoice) =>
            selectedPeriod === SHOW_ALL_INVOICES
                ? true
                : asDate(invoice.fakturaDato ?? "")
                      .getFullYear()
                      .toString() === selectedPeriod
        )
        // filter by invoice number
        .filter((invoice) => {
            const invoiceNumber = invoice.versjon ? `${invoice.fakturaId}-${invoice.versjon}` : invoice.fakturaId;
            return invoiceNumber.includes(invoiceNumberFilter);
        });

    const excelExportLink = invoices.some((invoice) => invoice.avtaleOrganisasjonsType === "BEDRIFT")
        ? "fakturaoversikt/BEDRIFT"
        : "fakturaoversikt/FORENING_FORBUND";

    const navigateToInvoiceId = (invoiceId: string) => () => {
        navigate(invoiceId);
    };

    track({
        hendelse: Trackingkey.SeListe,
        type: "fakturaer",
        antall: filteredInvoices.length
    });

    return (
        <section className="bm-invoice-overview-table">
            <Typography component="h2" variant="heading-3">
                Alle fakturaer
            </Typography>
            <div className="bm-invoice-overview-table__filter">
                <form className="bm-invoice-overview-table__filter-inputs">
                    <Select
                        label="Velg periode"
                        labelProps={{ variant: "small" }}
                        name="periode"
                        items={availablePeriods}
                        value={selectedPeriod}
                        onChange={(e) => {
                            handlePeriodChange(e.target.value);
                            track({
                                hendelse: Trackingkey.Filter,
                                label: "Velg periode",
                                valg: e.target.value,
                                tilgjengeligeValg: availablePeriods.map((p) => (typeof p === "string" ? p : p.value))
                            });
                        }}
                    />
                    <SearchField
                        dataTestautoid="invoice-overview-search-invoicenumber"
                        match={invoiceNumberFilter}
                        matchedCount={10}
                        setMatch={handleInvoiceNumberSearchChange}
                        onBlur={(e) => updateInvoiceNumberSearchParam(e.target.value)}
                        label="Søk etter fakturanummer"
                        labelProps={{ variant: "small" }}
                        placeholder=""
                    />
                </form>
                {invoices.length > 0 && (
                    <ExcelExportLink
                        export={excelExportLink}
                        postfix={`?periode=${
                            selectedPeriod === SHOW_ALL_INVOICES ? "" : selectedPeriod
                        }&fakturanummer=${invoiceNumberFilter}`}
                    />
                )}
            </div>
            <Table fullWidth collapseToList>
                <TableHead>
                    <TableRow>
                        <TableHeader>Fakturadato</TableHeader>
                        <TableHeader>Fakturanummer</TableHeader>
                        <TableHeader>Type</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader align="right">Beløp</TableHeader>
                        {isSmallDevice && <TableHeader srOnly>Handlinger</TableHeader>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredInvoices.map((invoice) => (
                        <TableRow
                            key={invoice.id}
                            clickable={
                                isSmallDevice
                                    ? undefined
                                    : { onClick: navigateToInvoiceId(invoice.id), markClickedRows: false }
                            }
                        >
                            <TableCell data-th="Fakturadato">
                                {invoice.fakturaDato ? formatDate(asDate(invoice.fakturaDato)) : null}
                            </TableCell>
                            <TableCell data-th="Fakturanummer">
                                {invoice.fakturaId}
                                {invoice.versjon ? `-${invoice.versjon}` : null}
                            </TableCell>
                            <TableCell data-th="Type">
                                {invoice.invoiceEventType
                                    .split("_")
                                    .map((w) => {
                                        const _w = w.toLocaleLowerCase();
                                        return _w.charAt(0).toUpperCase() + _w.slice(1);
                                    })
                                    .join(" ")}
                            </TableCell>
                            <TableCell data-th="Status">
                                <StatusTag
                                    status={invoice.status}
                                    dueDate={invoice.betalingsFrist}
                                    invoiceEventType={invoice.invoiceEventType}
                                />
                            </TableCell>
                            <TableCell align="right" data-th="Beløp">
                                {formatValuta(invoice.belop)}
                            </TableCell>
                            {isSmallDevice && (
                                <TableCell align="right">
                                    <Link className="jkl-nav-link" to={invoice.id}>
                                        Se detaljer
                                    </Link>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {filteredInvoices.length === 0 ? (
                <Typography
                    className="jkl-spacing-64--top w-full text-center"
                    variant="heading-2"
                >
                    Vi fant ingen fakturaer
                </Typography>
            ) : null}
        </section>
    );
};
