import { queryClient } from "src/common/queryClient";
import type { TaskResponseDto } from "@model/gen/models/TaskResponseDto";

import { useBruker } from "@features/bruker/queries";
import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";

const taskeQueryKey = ["task"];

export async function getTaskList() {
    const data = await http.get<TaskResponseDto[]>("task");
    return data as TaskResponseDto[];
}

export const taskListQuery = () => {
    return queryClient.fetchQuery({
        queryKey: taskeQueryKey,
        queryFn: () => getTaskList()
    });
};

export const useTasks = () => {
    const { bruker } = useBruker();

    return useQuery({
        queryKey: taskeQueryKey,
        queryFn: () => getTaskList(),
        enabled: !!bruker?.gjeldendeOrganisasjonsnummer,
        staleTime: 1000 * 60 * 5
    });
};

export const updateTask = async (taskId: string, status: string) => {
    await http.post(`task/${taskId}/${status}`);
};

export const filterTasks = (
    tasks: TaskResponseDto[],
    category?: string,
    status?: TaskResponseDto["status"][],
    productCode?: string,
    agreementId?: string
) => {
    if (!tasks) {
        return [];
    }
    return tasks.filter(
        (task: TaskResponseDto) =>
            (category ? task.category?.toLowerCase() === category.toLowerCase() : true) && // if category is not set, return all tasks
            (status
                ? status?.includes(task.status)
                : task.status?.includes("sendt") && // if status is not set, return only unread, og uncompleted tasks
                    // (productCode ? task.productCode === productCode : true) && // if productCode is not set, return all tasks
                    agreementId
                  ? task.url?.includes(agreementId)
                  : true) && // if agreementId is not set, return all tasks
            task.url // if task has no url, don't show it
    );
};
